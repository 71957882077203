import React, { useState,useEffect } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card, Chip, Stack } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate, useParams } from 'react-router-dom';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import { Helpers } from '../../../services/Helpers';
import PersonalDetails from './PersonalDetail';
import AddressDetails from './AddressDetails';
import BusinessDetails from './BusinessDetails';
import ServiceTable from './ServiceTable';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import WorkingHours from './AvailabilityDetails';
import { ArrowBack, List } from '@mui/icons-material';
import StoreFrontDetails from './StoreFrontDetails';
import { PermissionGuard } from '../../../components/PermissionGuard';
import Loading from '../../../components/Loader/Loading';
import { set } from 'zod';
import ReviewStatusIndicator from './ReviewStatusIndicator';
import AccountStatusIndicator from './AccountStatusIndicator';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';
import { formatDateTime } from '../../../utils/dateUtils';
import { act } from '@testing-library/react';
import IsTesterIndicator from './IsTesterIndicator';
// import PaymentDetails from './PaymentDetails';


const ViewProfile = () => {
  const {id} = useParams();
  const {step} = useParams();
  const navigate =  useNavigate();
  const [coverImage, setCoverImage] = useState('https://via.placeholder.com/1200x300');
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [activeTab, setActiveTab] = useState(parseInt(step) || 0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const [fullProviderDetails, setFullProviderDetails] = useState({}); // This will hold all the details of the provider
  const [personalDetails, setPersonalDetails] = useState({});
  const [businessDetailsData,setBusinessDetailsData] = useState({});
  const [serviceDetailsData,setServiceDetailsData] = useState([]);
  const [availabilityDetailData,setAvailabilityDetailData] = useState([]);
  const [storeFrontDetailData,setStoreFrontDetailData] = useState(
    {
        providerId: '',
        id:'',
        name: '',
        categories: [],
        image: '',
        gallery: [],
        description: '',
    }
)
  const fetchUserDetails = async () => {
    setIsLoading(true);
    const userDetailResponse = await ProviderServices.fetchProviderDetailsById(id);
    if (userDetailResponse.success == '1' && userDetailResponse?.data?.userType=="4") {
        console.log("user details:",userDetailResponse.data);
        setFullProviderDetails(userDetailResponse.data);
        setAccountStatus(userDetailResponse.data?.reviewStatus);
        setIsActive(userDetailResponse.data?.isActive);
        const {userId,firstName,middleName,lastName,email,dob,gender,profileImage,waiveVisitCharge} = userDetailResponse.data?.UserProfile ?? {};
        const transformAddresses = (currentState, userAllAddress) => {
            // Create a copy of the current state to avoid direct mutation
            const updatedState = { ...currentState };
          
            // Safely iterate through userAllAddress
            userAllAddress?.forEach(addr => {
              if (addr.serviceArea === '1') {
                updatedState.serviceAddress = {
                  serviceArea: '1',
                  fullName: addr.fullName || '',
                  mobileNumber: addr.mobileNumber || '',
                  pincode: addr.pincode || '',
                  latitude: addr.latitude || '',
                  longitude: addr.longitude || '',
                  city: addr.city || '',
                  state: addr.state || '',
                  landmark: addr.landmark || '',
                  address: addr.address || '',
                  addressType: addr.addressType || '1'
                };
              } else if (addr.serviceArea === '2') {
                updatedState.selfAddress = {
                  serviceArea: '2',
                  fullName: addr.fullName || '',
                  mobileNumber: addr.mobileNumber || '',
                  pincode: addr.pincode || '',
                  latitude: addr.latitude || '',
                  longitude: addr.longitude || '',
                  city: addr.city || '',
                  state: addr.state || '',
                  landmark: addr.landmark || '',
                  address: addr.address || '',
                  addressType: addr.addressType || '1'
                };
              }
            });
          
            return updatedState;
          };
        const { selfAddress, serviceAddress } = transformAddresses(personalDetails,userDetailResponse.data.userAllAddress??[]);
        
        const providerCat = userDetailResponse.data?.providerCat??[];
        // Extract serviceCategoryId and convert to strings
        const transformserviceCategories = providerCat?.map(item => String(item.serviceCategoryId));
        setPersonalDetails(prev=>{
            return {...prev,
                // providerId:userId,
                image:profileImage,
                firstName,middleName,lastName,email,dob,gender,waiveVisitCharge,
                isUserType: 'provider',
                serviceCategories: transformserviceCategories, 
                serviceAddress,
                selfAddress,
                mobileNumber:userDetailResponse.data.mobileNumber,
            }
        });
        // console.log('Self Address:', selfAddress);
        // console.log('Service Address:', serviceAddress);
        const {id,createdBy,updatedBy,createdAt,updatedAt,...restAll} = userDetailResponse.data?.UserBusinessDetail ?? {};
        setBusinessDetailsData(restAll);
        // setServiceDetailsData();
        const providerStorefrontData = userDetailResponse.data?.Storefront;
        // console.log("storefront Data:",providerStorefrontData);
        if (providerStorefrontData) {
          const categories =  providerStorefrontData.StorefrontCategories?.map(item => String(item.categoryId));
          const {StorefrontCategories,...restAll} = providerStorefrontData;
            setStoreFrontDetailData(pre=>({...pre,...restAll,categories}))
        }
        setIsLoading(false);
    }else{
        setIsLoading(false);
        Swal.fire('Failed to fetch Service Expert Details','','error')
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [id]);
  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setCoverImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    // Here you would typically send the updated data to your backend
    setEditMode(false);
  };

  // Inside ViewProfile component, add status to your state:
  const [accountStatus, setAccountStatus] = useState(); //review status
  const [isActive,setIsActive] = useState(); //account status

  const handleStatusChange = async (newStatus, reason) => {

    try {
      Swal.fire({
        title: 'Processing...',
        text: 'Please wait while we updaing status',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      // Call your API to update the status
      const response = await ProviderServices.modifyReviewStatus({
        providerId: id,
        reviewStatus: newStatus,
        reviewComment: reason
      });
      console.log('Response:', response);
      if (response.success == '1') {
        // setAccountStatus(newStatus);
        fetchUserDetails();
        Swal.fire({
          title: 'Status Updated',
          text: 'The account status has been successfully updated',
          icon: 'success',
          timer: 1500
        });
        
        return true;
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.message || 'Failed to update account status'
        });
        return false;
      }
    } catch (error) {
      await Swal.fire({
        title: 'Update Failed',
        text: error.message || 'Failed to update account status',
        icon: 'error'
      });
    }
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={0}>
            <PersonalDetails personalDetails={personalDetails}/>
            <AddressDetails PersonalDetails={personalDetails}/>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={0}>
            <BusinessDetails businessDetailsData={businessDetailsData}/>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={0}>
            <ServiceTable/>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={0}>
            <WorkingHours/>
          </Grid>
        );
      case 4:
        return (
          <Grid container spacing={0}>
            <StoreFrontDetails storeFrontDetailData={storeFrontDetailData} />
          </Grid>
        );
      // case 5:
      //   return (
      //     <Grid container spacing={0}>
      //       <PaymentDetails/>
      //     </Grid>
      //   );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loading/>}
      <PageHeaderLayout headerTitle={'Service Expert Profile'}>
        <Button endIcon={<List/>} variant='contained' size='small' onClick={()=>navigate('/admin/users')}>
          Expert List
        </Button>
        {/* <Button variant='contained' size='small' onClick={()=>navigate('/admin/users')}>
          Show Expert List
        </Button>
        <Button variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/0`)}>
          Add New Expert
        </Button> */}
      </PageHeaderLayout>
      <Grid container spacing={1} sx={{ my: '0%' }}>
        <Grid item xs={12} lg={3} sx={{pt:'.5%!important'}}>
          <Paper>
            {/* <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={personalDetails.image ? `${Helpers.ImagePathUrl()}/profile/sm/${personalDetails.image}`:profileImage}
                  // alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              <Typography variant="h4">{personalDetails.firstName} {personalDetails.middileName} {personalDetails.lastName}</Typography>
              <Typography variant="body2" color="text.secondary">
                 
              </Typography>
              <Chip
                label={'Service Expert'}
                color="primary"
                variant="outlined"
                size="small"
              />
            </Box> */}
            
            <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={personalDetails.image ? `${Helpers.ImagePathUrl()}/profile/sm/${personalDetails.image}`:profileImage}
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              
                <Typography variant="body1" fontWeight={600}>
                  {personalDetails.firstName || ''} {personalDetails.middleName || ''} {personalDetails.lastName || ''}
                </Typography>
                {/* <Chip
                  label={'Service Expert'}
                  color="primary"
                  variant="outlined"
                  size="small" 
                  sx={{fontSize:'0.5rem',height:'fit-content',py:'1%'}}
                /> */}
                <ReviewStatusIndicator comment={fullProviderDetails?.reviewComment} status={accountStatus} onStatusChange={handleStatusChange}/>
                <AccountStatusIndicator status={isActive} fetchUserDetails={fetchUserDetails}/>
            </Box>
            <Tabs              
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
              >
              <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Personal Details" />
              <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Business Details" />
              <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Service Details" />
              <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Availabilty Details" />
              <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Storefront Details" />
              {/* <Tab sx={{borderBottom:'1px solid lightGray',fontSize:'.8rem',minHeight:0}} label="Payment Details" /> */}
            </Tabs>
          </Paper>
          <Card sx={{ mt: 1,p:'2%' }}>
            <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
              <Typography sx={{fontWeight:500,color:'#3e3e3e',fontSize:'.7rem'}}>Created At :</Typography>
              <Typography sx={{fontSize:'.5rem'}}>
                { activeTab === 0 && fullProviderDetails?.UserProfile?.createdAt ? formatDateTime(fullProviderDetails?.UserProfile?.createdAt) : '' }
                { activeTab === 1 && fullProviderDetails.UserBusinessDetail && formatDateTime(fullProviderDetails?.UserBusinessDetail?.createdAt) }
                { activeTab === 2 && fullProviderDetails.UserServiceDetails?.createdAt && formatDateTime(fullProviderDetails?.UserServiceDetails?.createdAt) }
                { activeTab === 3 && fullProviderDetails?.UserAvailabilityDetails?.createdAt && formatDateTime(fullProviderDetails?.UserAvailabilityDetails?.createdAt) }
                { activeTab === 4 && fullProviderDetails?.Storefront?.createdAt && formatDateTime(fullProviderDetails?.Storefront?.createdAt) }
              </Typography>
            </Box>
            {/* <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
              <Typography sx={{fontWeight:500,color:'#3e3e3e',fontSize:'.7rem'}}>Created By :</Typography>
              <Typography sx={{fontSize:'.5rem'}}>
                { activeTab === 0 && fullProviderDetails?.UserProfile?.createdBy }
                { activeTab === 1 && fullProviderDetails.UserBusinessDetail && fullProviderDetails?.UserBusinessDetail?.createdBy }
                { activeTab === 2 && fullProviderDetails.UserServiceDetails && fullProviderDetails?.UserServiceDetails?.createdBy }
                { activeTab === 3 && fullProviderDetails?.UserAvailabilityDetails && fullProviderDetails?.UserAvailabilityDetails?.createdBy }
                { activeTab === 4 && fullProviderDetails?.Storefront && fullProviderDetails?.Storefront?.createdBy }
              </Typography>
            </Box> */}
            <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
              <Typography sx={{fontWeight:500,color:'#3e3e3e',fontSize:'.7rem'}}>Last Updated At :</Typography>
              <Typography sx={{fontSize:'.5rem'}}>
                { activeTab === 0 && fullProviderDetails?.UserProfile?.updatedAt ? formatDateTime(fullProviderDetails?.UserProfile?.updatedAt) : '' }
                { activeTab === 1 && fullProviderDetails.UserBusinessDetail && formatDateTime(fullProviderDetails?.UserBusinessDetail?.updatedAt) }
                { activeTab === 2 && fullProviderDetails.UserServiceDetails?.createdAt && formatDateTime(fullProviderDetails?.UserServiceDetails?.updatedAt) }
                { activeTab === 3 && fullProviderDetails?.UserAvailabilityDetails?.createdAt && formatDateTime(fullProviderDetails?.UserAvailabilityDetails?.updatedAt) }
                { activeTab === 4 && fullProviderDetails?.Storefront?.createdAt && formatDateTime(fullProviderDetails?.Storefront?.updatedAt) }
              </Typography>
            </Box>
            {/* <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
              <Typography sx={{fontWeight:500,color:'#3e3e3e',fontSize:'.7rem'}}>Last Modification By :</Typography>
              <Typography sx={{fontSize:'.5rem'}}>
                { activeTab === 0 && fullProviderDetails?.UserProfile?.updatedBy }
                { activeTab === 1 && fullProviderDetails.UserBusinessDetail && fullProviderDetails?.UserBusinessDetail?.updatedBy }
                { activeTab === 2 && fullProviderDetails.UserServiceDetails && fullProviderDetails?.UserServiceDetails?.updatedBy }
                { activeTab === 3 && fullProviderDetails?.UserAvailabilityDetails && fullProviderDetails?.UserAvailabilityDetails?.updatedBy }
                { activeTab === 4 && fullProviderDetails?.Storefront && fullProviderDetails?.Storefront?.updatedBy }
              </Typography>
            </Box> */}
          </Card>
          <IsTesterIndicator isTester={fullProviderDetails?.isTester} fetchUserDetails={fetchUserDetails}/>
        </Grid>
        <Grid item xs={12} lg={9} sx={{pt:'.5%!important'}}>
          <Box sx={{ flexGrow: 1, }}>
            <Paper elevation={3} >
              {renderForm()}
            </Paper>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <PermissionGuard feature='service-expert' permission='canEdit'>
                <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}/${activeTab+1}`)}>
                  Edit
                </Button>
              </PermissionGuard>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewProfile;