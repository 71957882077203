import { AccountCircle, Assignment, Cancel, Chat, Close, CloseOutlined, Edit, HighlightOffRounded, PersonOutline } from '@mui/icons-material';
import { Box, Card, Chip, Dialog, DialogContent, Fab, FormControl, Grid, IconButton, MenuItem, Paper, Rating, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ProviderServices } from '../Provider/CrudServices';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller, set } from 'react-hook-form';
import Swal from 'sweetalert2';
import { PermissionGuard } from '../../components/PermissionGuard';
import { isMobile } from 'react-device-detect';
import ChatBox from './ChatBox';
import PaymentDetails from './PaymentDetails';
import { useParams } from 'react-router-dom';
import { BookingService } from './Service';
import Loading from '../../components/Loader/Loading';

dayjs.extend(customParseFormat);

const Viewdetails = ({selectedBookingDetails,setSelectedBookingDetails,bookingId}) => {
    // const {bookingId} = useParams();
    const [isloading,setIsLoading] = useState(false);
    const [bookingDetailsdata,setbookingDetailsdata] = useState({});
    const fetchBookingDetalById = async()=>{
        setIsLoading(true);
        try{
            const response = await BookingService.bookingDetailById(bookingId);
            console.log('Booking Details:',response);
            setbookingDetailsdata(response.data);
        }
        catch(error){
            console.log('Error:',error);
        }finally{
            setIsLoading(false);
        }

    }
    useEffect(()=>{
        if(selectedBookingDetails){
            setbookingDetailsdata(selectedBookingDetails);
        }else if(bookingId){
            fetchBookingDetalById();
        }
    },[selectedBookingDetails,bookingId])
    const { control,watch,setValue } = useForm({
        defaultValues: {
            bookingStatus: bookingDetailsdata?.bookingStatus
        }
    });
    const [changeBookingStatus,setChangeBookingStatus] = useState(false);
    const [dialogOpen,setDialogOpen] = useState(true);

    // const [providerDetails,setProviderDetails] = useState(null);
    // const fetchProviderDetails = async (providerId)=>{
    //     const providerDetailResposne = await ProviderServices.fetchProviderDetailsById(providerId);
    //     setProviderDetails(providerDetailResposne.data);
    // }
    // useEffect(()=>{
    //     if(bookingDetailsdata?.providerId){
    //         fetchProviderDetails(bookingDetailsdata?.providerId);
    //     }

    // },[bookingDetailsdata])

    const bookingStatus = watch('bookingStatus');
    const changeBookingStatusHandler = async()=>{
        const result = await Swal.fire({
            text: `Are you sure you want to change the booking status from ${bookingDetailsdata?.bookingStatus} to ${bookingStatus} now?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
        });
        if (result.isConfirmed) {
            // try {
            //     Swal.fire({
            //       title: 'Processing...',
            //       text: 'Please wait while we updaing status',
            //       allowOutsideClick: false,
            //       allowEscapeKey: false,
            //       showConfirmButton: false,
            //       didOpen: () => {
            //         Swal.showLoading();
            //       }
            //     });
            //     // Call your API to update the status
            //     const response = await ProviderServices.modifyAccountStatus(id);
            //     console.log('Response:', response);
            //     if (response.success == '1') {
            //       // setAccountStatus(newStatus);
            //       fetchUserDetails();
            //       Swal.fire({
            //         title: 'Status Updated',
            //         text: 'The account status has been successfully updated',
            //         icon: 'success',
            //         timer: 1500
            //       });
                  
            //       return true;
            //     } else {
            //       await Swal.fire({
            //         icon: 'error',
            //         title: 'Error!',
            //         text: response.message || 'Failed to update account status'
            //       });
            //       return false;
            //     }
            // } catch (error) {
            //     await Swal.fire({
            //       title: 'Update Failed',
            //       text: error.message || 'Failed to update account status',
            //       icon: 'error'
            //     });
            // }
            Swal.fire('Status Changed!', '', 'success')
        } else {
            return false;
        }
    }
    useEffect(()=>{
        if(bookingStatus && bookingStatus !== bookingDetailsdata?.bookingStatus){
            changeBookingStatusHandler();
        }
    },[bookingStatus])
    const [showChat,setShowChat] = useState(false);
  return (
    <>
    {isloading && <Loading/>}
    <Grid container spacing={1}>
        <Grid item xs={isMobile?12:8.5}>
            {/* <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%'}}>
                <Typography variant={'h5'}>Booking Detail's </Typography>
                <IconButton size='small' color='error' onClick={()=>setbookingDetailsdata(null)}><Close/></IconButton>
            </Card> */}
            {/*<Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
                <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                    <Typography sx={{fontWeight:600,fontSize:'1rem',color:'#464166'}}>Booking Details </Typography>
                    <Assignment sx={{fontSize:'1rem'}}/>
                </Box>
                <IconButton size='small'  onClick={()=>setbookingDetailsdata(null)}><HighlightOffRounded/></IconButton>
            </Card> */}
            <Card>
                <Box compo sx={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid lightGray',p:'1% 2%'}}>
                    <Typography fontWeight={'600'} sx={{fontSize:'0.85rem'}}>Booking Number : <Box component={'span'} sx={{color:'#1976d2',fontSize:'0.75rem'}}>{bookingDetailsdata?.bookingNumber}</Box></Typography>
                    <Typography fontWeight={'600'} sx={{fontSize:'0.85rem'}}>Service Date : <Box component={'span'} sx={{color:'#1976d2',fontSize:'0.75rem'}}>{formatDateTime(bookingDetailsdata?.serviceDate,'date')}</Box></Typography>     
                    <Box sx={{display:'flex',gap:1,width:`${changeBookingStatus &&'27%'}`,alignItems:'center'}}>
                        <Typography fontWeight={'600'} sx={{fontSize:'0.85rem'}}>Status: </Typography>

                            {/* //  sx={{textTransform:'uppercase',color:bookingDetailsdata?.bookingStatus === 'requested' ? '#E8832A' : bookingDetailsdata?.bookingStatus === "rejected"?'#F80000':bookingDetailsdata?.bookingStatus === 'completed'?'#009EB4':bookingDetailsdata?.bookingStatus === 'accepted'?'#40B962': bookingDetailsdata?.bookingStatus === 'cancelled'?'#D81A23':bookingDetailsdata?.bookingStatus === 'ongoing'?'#1A44B8':'default',fontSize:'0.75rem'}}>{bookingDetailsdata?.bookingStatus} */}
                            {changeBookingStatus ? 
                                <>
                                <FormControl size="small" fullWidth
                                    sx={{
                                        // width: "fit-content",
                                        // border:`1px solid ${statusConfigs[selectedStatus]?.colorCode}`,
                                        // borderRadius: '4rem',
                                        // '& .MuiInput-underline': {
                                        // '&:before': {
                                        //     borderBottom: 'none'  // Remove default bottom border
                                        // },
                                        // '&:after': {
                                        //     borderBottom: 'none'  // Remove focus bottom border
                                        // },
                                        // '&:hover:not(.Mui-disabled):before': {
                                        //     borderBottom: 'none'  // Remove hover bottom border
                                        // }
                                        // },
                                        // // Style the dropdown icon (arrow)
                                        // '& .MuiSelect-icon': {
                                        //     color: statusConfigs[selectedStatus]?.colorCode, // Change arrow color
                                        //     right: 0,
                                        // }
                                    }}                 
                                >
                                    {/* <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel> */}
                                    <Controller
                                        name="bookingStatus"
                                        control={control}
                                        // defaultValue=""
                                        render={({ field }) => (
                                        <Select
                                            {...field}
                                            // label="Select Status"
                                            // renderValue={(value) => (
                                            //     <Box sx={{ display: 'flex', justifyContent:'center',height:'1rem'}}>
                                                
                                            //         <Chip

                                            //             icon={statusConfigs[value]?.icon}
                                            //             label={statusConfigs[value]?.label}
                                            //             color={statusConfigs[value]?.color}
                                            //             size="small"
                                            //             variant="outlined"
                                            //             sx={{ml:1,fontSize:'0.5rem',height:'fit-content',py:'1%',border:'none',
                                            //             '& .MuiChip-icon':{
                                            //                 fontSize:'.6rem'
                                            //             }
                                            //             }}
                                            //         />
                                                
                                            //     </Box>
                                            // )}
                                            sx={{
                                            minHeight: '0px',
                                            height: '1.6rem',
                                            fontSize: '0.65rem',
                                            // fontWeight: '600',
                                            '& .MuiSelect-select': {
                                                padding: '0 .5rem', // Adjust horizontal padding as needed
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                            }}
                                        >
                                            {/* {
                                                statusConfigs.map((config, key) => (
                                                    <MenuItem sx={{fontSize:'0.6rem'}} value={config?.value}>{config?.label}</MenuItem>
                                                ))
                                            } */}
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='requested'>Requested</MenuItem>
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='accepted'>Accepted</MenuItem>
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='rejected'>Rejected</MenuItem>
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='cancelled'>Cancelled</MenuItem>
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='completed'>Completed</MenuItem>
                                            <MenuItem sx={{fontSize:'0.65rem'}} value='ongoing'>Ongoing</MenuItem>
                                        </Select>
                                        )}
                                    />
                                </FormControl> 
                                    <IconButton size='small' color='error' sx={{fontSize:'0.85rem'}} onClick={()=>setChangeBookingStatus(false)}><Cancel sx={{fontSize:'1rem'}}/></IconButton>
                                </>
                                :
                                <>
                                <Chip
                                    label={bookingDetailsdata?.bookingStatus}
                                    size="small"
                                    sx={{
                                        // ml:1,
                                        fontSize:'0.75rem',
                                        fontWeight:'500',
                                        color:bookingDetailsdata?.bookingStatus === 'requested' ? '#E8832A' : bookingDetailsdata?.bookingStatus === "rejected"?'#F80000':bookingDetailsdata?.bookingStatus === 'completed'?'#009EB4':bookingDetailsdata?.bookingStatus === 'accepted'?'#40B962': bookingDetailsdata?.bookingStatus === 'cancelled'?'#D81A23':bookingDetailsdata?.bookingStatus === 'ongoing'?'#1A44B8':'default',
                                        bgcolor:bookingDetailsdata?.bookingStatus === 'requested' ? '#FFEDD5' : bookingDetailsdata?.bookingStatus === "rejected"?'#f800001f':bookingDetailsdata?.bookingStatus === 'completed'?'#009eb442':bookingDetailsdata?.bookingStatus === 'accepted'?'#40b96236':bookingDetailsdata?.bookingStatus === 'cancelled'?'#d81a233b':bookingDetailsdata?.bookingStatus === 'ongoing'?'#1a44b838': 'default'
                                    }}
                                />
                                {/* <PermissionGuard feature='booking' permission='canEdit'>
                                    <IconButton size='small' color='warning' sx={{fontSize:'0.85rem'}} onClick={()=>setChangeBookingStatus(true)}><Edit sx={{fontSize:'1rem'}}/></IconButton>
                                </PermissionGuard> */}
                                </>    
                        }
                    </Box>

                </Box>
                <Box sx={{my:'1%',px:'2%'}}>
                    <TableContainer component={Paper}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:600,fontSize:'0.75rem'}}>Requested Services</TableCell>
                                    <TableCell sx={{fontWeight:600,fontSize:'0.75rem'}}>Quantity</TableCell>
                                    <TableCell sx={{fontWeight:600,fontSize:'0.75rem'}}>Time Slot</TableCell>
                                    <TableCell sx={{fontWeight:600,fontSize:'0.75rem',textAlign:'right'}}>Price</TableCell>
                                    <TableCell sx={{fontWeight:600,fontSize:'0.75rem',textAlign:'right'}}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookingDetailsdata?.bookingItems && bookingDetailsdata?.bookingItems.length>0 && bookingDetailsdata?.bookingItems.map((item,index)=>(
                                    <TableRow key={index}>
                                        <TableCell sx={{ fontSize:'0.75rem',fontWeight:'500'}}>{item.serviceName}</TableCell>
                                        <TableCell sx={{ fontSize:'0.75rem',pl:'3%'}}>{item.quantity}</TableCell>
                                        <TableCell sx={{ fontSize:'0.75rem',}}>
                                            {dayjs(bookingDetailsdata?.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - 
                                            {dayjs(bookingDetailsdata?.bookingEndTime, 'HH:mm:ss').format('h:mm A')}
                                        </TableCell>
                                        <TableCell sx={{ fontSize:'0.75rem',textAlign:'right'}}>₹{item.price}</TableCell>
                                        <TableCell sx={{ fontSize:'0.75rem',textAlign:'right'}}>₹{(item.price * item.quantity).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                
                                {/* Charges Section */}
                                <TableRow sx={{}}>
                                    <TableCell rowSpan={bookingDetailsdata?.chargeDetails?.additionalCharge > 0 ?'5':'4'} />
                                    <TableCell colSpan={3} sx={{textAlign:'right', fontWeight:'600',borderBottom:'none',fontSize:'0.75rem'}}>SubTotal:</TableCell>
                                    <TableCell sx={{textAlign:'right',borderBottom:'none',fontSize:'0.75rem'}}>₹{bookingDetailsdata?.subTotal}</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                    <TableCell colSpan={3} sx={{textAlign:'right',borderBottom:'none',fontSize:'0.75rem'}}>Visiting Charge:</TableCell>
                                    <TableCell sx={{textAlign:'right',borderBottom:'none',fontSize:'0.75rem',}}>
                                    ₹{bookingDetailsdata?.visitingCharge}   
                                    {/* ₹{parseFloat(bookingDetailsdata?.subTotal) >= parseFloat(bookingDetailsdata?.waiveVisitCharge) ? (bookingDetailsdata?.chargeDetails?.vistingCharge) : 0} */}
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell colSpan={3}  sx={{textAlign:'right',borderBottom:'none'}}>Waive Visiting Charge:</TableCell>
                                    <TableCell sx={{textAlign:'right',borderBottom:'none',}}>
                                        <Box component={'span'}><Typography variant='caption' color={'darkseagreen'}>(-)</Typography></Box>₹{parseFloat(bookingDetailsdata?.subTotal) >= parseFloat(bookingDetailsdata?.waiveVisitCharge) ? (bookingDetailsdata?.chargeDetails?.vistingCharge) : 0}
                                    </TableCell>
                                </TableRow> */}
                                
                                {bookingDetailsdata?.chargeDetails?.additionalCharge > 0 && (
                                    <TableRow>
                                        <TableCell colSpan={3}  sx={{textAlign:'right',borderBottom:'none',fontSize:'0.75rem'}}>Additional Charge:</TableCell>
                                        <TableCell 
                                            sx={{textAlign:'right',borderBottom:'none',fontSize:'0.75rem', 
                                                
                                                // position: 'relative',
                                                // '&::before': {
                                                // content: '"₹"',
                                                // }
                                            }}
                                        >
                                            ₹{parseFloat(bookingDetailsdata?.additionalCharge||0).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                
                                <TableRow>
                                    <TableCell colSpan={3} sx={{textAlign:'right',fontSize:'0.75rem',borderBottom:'none'}}>Tax:</TableCell>
                                    <TableCell sx={{textAlign:'right',fontSize:'0.75rem',borderBottom:'none'}}>₹{bookingDetailsdata?.tax}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={3} sx={{textAlign:'right',fontSize:'0.75rem'}}>Used Coin:</TableCell>
                                    <TableCell sx={{textAlign:'right',fontSize:'0.75rem'}}>₹{parseFloat(bookingDetailsdata?.coinValue).toFixed(2)}</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                    <TableCell colSpan={4} sx={{
                                        textAlign:'right', 
                                        fontWeight:'600',
                                        borderBottom: 'none',fontSize:'0.75rem'
                                    }}>Total Amount:</TableCell>
                                    <TableCell sx={{
                                        textAlign:'right',
                                        fontWeight:'600',
                                        borderBottom: 'none',fontSize:'0.75rem'
                                    }}>
                                        {/* {parseFloat(
                                            parseFloat(bookingDetailsdata?.subTotal) + 
                                            parseFloat(bookingDetailsdata?.chargeDetails?.additionalCharge) +
                                            parseFloat(bookingDetailsdata?.tax) + 
                                            parseFloat(bookingDetailsdata?.chargeDetails?.vistingCharge)
                                        ).toFixed(2)} */}
                                        ₹{bookingDetailsdata?.total}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{my:'1%',px:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                        <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Customer Details</Typography>
                        <AccountCircle sx={{fontSize:'1rem'}}/>
                    </Box>
                    <Paper sx={{p:'2%'}}>
                        <Box sx={{display:'flex',alignItems:'baseline'}}>
                            <Typography fontWeight={'600'} sx={{width:'30%',color:'#3E3E3E',fontSize:'0.75rem'}}>Name</Typography>
                            <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                            <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',textTransform:'capitalize',color:'#181818'}}>{bookingDetailsdata?.add_customerFullName}</Typography>    
                        </Box>
                        <Box sx={{display:'flex',alignItems:'baseline'}}>
                            <Typography fontWeight={'600'} sx={{width:'30%',color:'#3E3E3E',fontSize:'0.75rem'}}>Contact</Typography>
                            <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                            <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',width:'68%',color:'#181818'}}>{bookingDetailsdata?.add_mobileNumber}</Typography>         
                        </Box>
                        <Box sx={{display:'flex',alignItems:'baseline'}}>
                            <Typography fontWeight={'600'} sx={{width:'30%',color:'#3E3E3E',fontSize:'0.75rem'}}>Address</Typography>
                            <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                            <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',width:'68%',color:'#181818'}}>{bookingDetailsdata?.add_address}</Typography>         
                        </Box>
                        <Box sx={{display:'flex',alignItems:'baseline'}}>
                            <Typography fontWeight={'600'} sx={{width:'30%',color:'#3E3E3E',fontSize:'0.75rem'}}>Landmark</Typography>
                            <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                            <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',width:'68%',color:'#181818'}}>{bookingDetailsdata?.add_landmark}</Typography>         
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{my:'1%',px:'2%'}}>
                    <Box sx={{display:'flex',alignItems:'center',gap:2}}>
                        <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Provider Details</Typography>
                        <PersonOutline sx={{fontSize:'1rem'}}/>
                        
                    </Box>
                    <Box sx={{display:'flex',alignItems:'baseline',gap:2}}>
                        <Paper sx={{p:'2%',flex:1}}>
                            <Box sx={{display:'flex',alignItems:'flex-end'}}>
                                <Typography fontWeight={'600'} sx={{width:'45%',color:'#3E3E3E',fontSize:'0.75rem'}}>Name</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',textTransform:'capitalize',color:'#181818'}}>{bookingDetailsdata?.providerName}</Typography>    
                            </Box>
                            <Box sx={{display:'flex',alignItems:'flex-end'}}>
                                <Typography fontWeight={'600'} sx={{width:'45%',color:'#3E3E3E',fontSize:'0.75rem'}}>Contact</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Typography fontWeight={'400'} sx={{fontSize:'0.65rem'}}>{bookingDetailsdata?.provider?.mobileNumber||''}</Typography>         
                            </Box>
                            <Box sx={{display:'flex',alignItems:'flex-end'}}>
                                <Typography fontWeight={'600'} sx={{width:'45%',color:'#3E3E3E',fontSize:'0.75rem'}}>waiveVisitCharge</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Typography fontWeight={'400'} sx={{fontSize:'0.65rem'}}>₹{bookingDetailsdata?.provider?.UserProfile?.waiveVisitCharge||'0.00'}</Typography>         
                            </Box>
                        </Paper>
                        <Paper sx={{p:'2%',flex:1.5}}>
                            <Box sx={{display:'flex',alignItems:'flex-end'}}>
                                <Typography fontWeight={'600'} sx={{width:'40%',color:'#3E3E3E',fontSize:'0.75rem'}}>Avg. Rating</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Rating name="half-rating-read" defaultValue={0} precision={0.5} value={bookingDetailsdata?.provider?.avgRating} readOnly size='small'/>
                                <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',textTransform:'capitalize',color:'#181818'}}>{`(${bookingDetailsdata?.provider?.countRating||0})`}</Typography>    
                            </Box>
                            <Box sx={{display:'flex',alignItems:'flex-end'}}>
                                <Typography fontWeight={'600'} sx={{width:'40%',color:'#3E3E3E',fontSize:'0.75rem'}}>Customer Rating</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Rating name="half-rating-read" defaultValue={0} precision={0.5} value={bookingDetailsdata?.rating?.rating} readOnly size='small'/>
                                {/* <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',textTransform:'capitalize',color:'#181818'}}>{bookingDetailsdata?.providerName}</Typography>     */}
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <Typography fontWeight={'600'} sx={{width:'40%',color:'#3E3E3E',fontSize:'0.75rem'}}>Customer Comment</Typography>
                                <Typography fontWeight={'600'} sx={{mr:'1%',fontSize:'0.65rem'}}>:</Typography>
                                <Typography fontWeight={'400'} sx={{fontSize:'0.65rem',textWrap:'wrap',width:'58%'}}>{bookingDetailsdata?.rating?.comment||'N/A'}</Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Card>
        </Grid>
        <Grid item xs={isMobile?12:3.5}>
            
                {(bookingDetailsdata?.bookingStatus == 'rejected' || bookingDetailsdata?.bookingStatus == 'cancelled') && (
                    <Card sx={{mb:'2%'}}>
                    <Box sx={{ my:'1%',px:'2%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,py:'3%' }}>
                            <Typography sx={{ fontWeight: '600',fontSize:'0.75rem' }}>{`Booking ${bookingDetailsdata?.bookingStatus == 'rejected' ? 'Rejection' : 'Cancellation'} Details`}</Typography>
                        </Box>
                        {/* <Paper sx={{ p: '2%' }}> */}
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography fontWeight={'600'} sx={{ width: '30%', color: '#3E3E3E',fontSize:'0.65rem' }}>Cancelled By</Typography>
                                <Typography fontWeight={'600'} sx={{ mr: '1%' }}>:</Typography>
                                <Typography fontWeight={'400'} sx={{ fontSize: '0.65rem', textTransform: 'capitalize', color: '#181818' }}>{bookingDetailsdata?.cancelBy}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography fontWeight={'600'} sx={{ width: '30%', color: '#3E3E3E',fontSize:'0.65rem' }}>Reason for {bookingDetailsdata?.bookingStatus == 'rejected' ? 'Rejection' : 'Cancellation'}</Typography>
                                <Typography fontWeight={'600'} sx={{ mr: '1%' }}>:</Typography>
                                <Typography fontWeight={'400'} sx={{ fontSize: '0.65rem', width: '68%', color: '#181818' }}>{bookingDetailsdata?.cancelMessage || bookingDetailsdata?.message || ''}</Typography>
                            </Box>
                        {/* </Paper> */}
                    </Box>
                    </Card>
                )}
            <PaymentDetails bookingDetailsdata={bookingDetailsdata}/>
        </Grid>
        {!showChat && <Fab variant="extended" size="small" color="primary" sx={{position:'fixed',bottom:5,right:5,textTransform:'capitalize'}} onClick={()=>setShowChat(!showChat)}>
            <Chat sx={{ mr: 1,fontSize:'1rem' }} />
            Show Chat
        </Fab>}
        {showChat && (
            <Card sx={{
                position: 'fixed',
                bottom: 5,
                right: 5,
                width: '25vw',
                height: '75vh',
                zIndex: 1050,
                overflow: 'hidden',
                border:'1px solid #6256e6'
            }}>   
                <ChatBox
                    onClose={() => setShowChat(false)}
                    bookingDetails={bookingDetailsdata}
                />
            </Card>
            )}
    </Grid>
    </>
  );
}

export default Viewdetails;
