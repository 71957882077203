import { useState, useEffect, useCallback } from 'react';
import { Card,Chip, Typography, Divider, Stack, TextField, Grid, Button, Box,Radio,RadioGroup,FormControlLabel, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, Badge, Avatar, Popover, InputAdornment } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid';
import { User } from './UserService';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, FormProvider, Controller, set } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ProviderServices } from './CrudServices';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { CustomerService } from '../Customer/Service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import calculateAge from '../../utils/ageCalculation';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { PermissionGuard } from '../../components/PermissionGuard';
import { Helpers } from '../../services/Helpers';
import { CheckCircle, Warning, Block, Pending, KeyboardArrowUp, KeyboardArrowDown, FilterList, Search, ArrowBack } from '@mui/icons-material';
import Loading from '../../components/Loader/Loading';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import AdvancedFilter from './AdvancedFilter';
import ExportAll from './filterAndExport/ExportAll';
import MultiColumnFilter from '../../components/Filter/MultiColumnFilter';
import { useDispatch,useSelector } from 'react-redux';
import { updateExpertTableState } from '../../storage/redux/action';
import QuickSearchTool from '../../components/QuickSearch/QuickSearchTool';

const statusConfigs = [
  {
    label: 'Review Pending',
    value: '0',
    color: 'warning',
    colorCode:'#ed6c02',
    icon: <Pending sx={{ fontSize: '0.25rem' }} />,
    tooltip: 'Account is under review'
  },
  {
      label: 'Approved',
      value: '1',
      color: 'success',
      colorCode:'#2e7d32',
      icon: <CheckCircle sx={{ fontSize: '0.25rem' }} />,
      tooltip: 'Account has been Approved Already'
    },
  {
    label: 'Rejected',
    value: '2',
    color: 'error',
    colorCode:'#d32f2f',
    icon: <Warning sx={{ fontSize: '0.25rem' }} />,
    tooltip: 'Account has been rejected'
  }
];

export default function UserList() {

  // Grid API Reference
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableState = useSelector(state => state.expertTableState);

  /************************  Table Data State Manage **************************** */

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 ,headerAlign: 'center',},
    { 
      field: 'name', 
      headerName: 'Profile', 
      flex: 1.75,
      headerAlign: 'center',
      sortable:false,
      valueGetter: (params) => {
        const id = params.row.id || '';
        // const firstName = params.row.UserProfile?.firstName || '';
        // const lastName = params.row.UserProfile?.lastName || '';
        const fullName = `${params.row.UserProfile?.firstName || ''} ${params.row.UserProfile?.middleName || ''} ${params.row.UserProfile?.lastName || ''}`;
        const email = params.row.UserProfile?.email || '';
        const age = calculateAge(params.row.UserProfile?.dob) || '';
        const mobile = params.row.mobileNumber || '';
        const reviewStatus = statusConfigs[params.row.reviewStatus]?.label || '';
        
        // Combine all profile information into a single searchable string
        return `${fullName} ${email} ${age} ${mobile} ${id} ${reviewStatus}`.trim();
      },
      renderCell: (params) => (
        
          
          <Box sx={{p: 1,display:'flex',justifyContent:'flex-start',width:'100%'}}>
            <Stack
              direction="column" 
              spacing={1} 
              // alignItems="center" 
              justifyContent="flex-start"
              sx={{ mt:1,width:'100%'}}
            >
              <Box sx={{display:'flex',gap:'5%' ,justifyContent:'flex-start',width:'100%'}}>
                <Badge color={params.row.isVerified?'success':'error'} badgeContent=" " variant='dot' sx={{display:'flex',alignItems:'center',justifyContent:'center',width:'fit-content',height:'fit-content'}}>
                <Avatar src={`${Helpers.ImagePathUrl()}/profile/sm/${params.row.UserProfile?.profileImage}`} />
                </Badge>
                
                <Box>
                  <Typography 
                    // onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
                    onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
                    variant="body2" 
                    sx={{cursor:'pointer', fontSize:'.65rem',fontWeight:500,
                      //on hover color
                      '&:hover':{
                        color:'warning.main'
                      }
                    }} 
                    color={params.row.UserProfile?.firstName? '#1976d2' : '#d32f2f'}
                  >
                    {`${params.row.UserProfile?.firstName || ''} ${params.row.UserProfile?.middleName || ''} ${params.row.UserProfile?.lastName || ''}`}
                    {!params.row.UserProfile?.firstName && 'Incomplete Profile'}
                    <Box component={'span'} sx={{fontSize:'.55rem',fontWeight:600,color:'#382cd5'}}>({params.row.id})</Box>
                  </Typography>
                  {/* <Chip
                    icon={<CheckCircle sx={{fontSize:'.75rem !important'}}/>}
                    label={'Reviewed'}
                    color={'success'}
                    size="small"
                    variant="outlined"
                    sx={{fontSize:'xx-small',height:'fit-content'}}
                  /> */}
                  <Chip
                    icon={statusConfigs[params.row.reviewStatus]?.icon}
                    label={statusConfigs[params.row.reviewStatus]?.label}
                    color={statusConfigs[params.row.reviewStatus]?.color}
                    size="small"
                    variant="outlined"
                    sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
                  />
                  <Box>
                    <Typography variant="caption" sx={{fontSize:'0.5rem'}}>{params.row.UserProfile?.email || 'N/A'}</Typography>
                    <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Mobile:</Box>{` ${params.row.mobileNumber}`}</Typography>
                    <Typography sx={{fontSize:'0.5rem'}}><Box component={'span'} sx={{fontWeight:600}}>Age: </Box>{`${calculateAge(params.row.UserProfile?.dob) || 'N/A'}`}</Typography>              
                  </Box>
                </Box>
                
              </Box>
            </Stack> 
          </Box>
      ),
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      headerAlign: 'center',sortable:false,
      filterable: false,
      flex:.6,
      valueGetter: (params) => {  
        const isActive = params.row.isActive ? 'Active' : 'Inactive';
        const isVerified = params.row.isVerified ? 'Verified' : 'non-verified';
        return `${isActive} ${isVerified}`;
      },
      renderCell: (params) => (
        <Box sx={{width:'max-content',m:'auto'}}>
          <Chip 
            label={params.row.isActive ? 'Active' : 'Inactive'} 
            color={params.row.isActive ? 'success' : 'error'} 
            size="small" 
            sx={{fontSize:'0.5rem',height:'fit-content',py:'5%'}}
        />
        </Box>
      ),
    },
    { 
      field: 'verification', 
      headerName: 'Verification', 
      headerAlign: 'center',sortable:false,
      filterable: false,
      flex:.75,
      valueGetter: (params) => {
        const isKycVerified = params.row.isKycVerified ? 'KYC' : '';
        const isPanVerified = params.row.isPanVerified ? 'PAN' : '';
        const isAadhharVerified = params.row.isAadhharVerified ? 'Aadhaar' : '';
        return `${isKycVerified} ${isPanVerified} ${isAadhharVerified}`;
      },
      renderCell: (params) => (
        <Box sx={{width:'60%',m:'auto'}}>
            {/*  */}
            {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.panNumber)&& 
              <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                  <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
                  <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>PAN</Typography>
                  {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
                  
              </Box>
            }
            {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.aadharNumber) && <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
                <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>Aadhaar</Typography>
                {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
            </Box>}
            {(!params.row.UserBusinessDetail || 
                  (params.row.UserBusinessDetail && 
                  (!params.row.UserBusinessDetail.panNumber || params.row.UserBusinessDetail.panNumber.trim() === '') && 
                  (!params.row.UserBusinessDetail.aadharNumber || params.row.UserBusinessDetail.aadharNumber.trim() === ''))
                ) && 
              <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
                <IconButton size='small' sx={{p:0}}><CancelIcon color='error' sx={{fontSize:'0.65rem'}}/></IconButton>
                <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>KYC</Typography>
                {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
              </Box>
            
            }
        </Box>
      ),
    },
    { 
      field: 'categories', 
      headerName: 'Categories', 
      headerAlign: 'center',sortable:false,
      filterable: false,
      flex:1.25,
      valueGetter: (params) => {
        return params.row.providerCat.map(cat => cat.proServCat.title).join(', ');
      },
      renderCell: (params) => {
        
    
        // Check if this specific row is expanded
        const isRowExpanded = expandedRowIds[params.row.id];
    
        return (
          <Box sx={{width:'100%',display:'flex',alignItems:'center',flexWrap:'wrap',gap:'1%'}}>
            {params.row.providerCat.length > 0 && params.row.providerCat.map((cat, index) => (
              <Box key={index} >
                {/* Always show first 3 categories */}
                {index < 3 && (
                  <>
                    <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
                    {/* <br/> */}
                  </>
                )}
                
                {/* Show additional categories when row is expanded */}
                {isRowExpanded && index >= 3 && (
                  <>
                    <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
                    {/* <br/> */}
                  </>
                )}
              </Box>
            ))}
            
            {/* Show more/less button if more than 4 categories */}
            {params.row.providerCat.length > 4 && (
              <Box sx={{width:'100%',textAlign:'end'}}>
                <Button 
                  size='small' 
                  sx={{fontSize:'.45rem'}} 
                  onClick={() => toggleRowExpansion(params.row.id)}
                >
                  {isRowExpanded ? 'view less' : `view ${params.row.providerCat.length-3} more`}
                </Button>
              </Box>
            )}
            
            {/* Show N/A if no categories */}
            <Typography sx={{
              fontSize:'0.5rem',
              textAlign:'center',
              width:'100%'
            }}>
              {params.row.providerCat.length === 0 && 'N/A'}
            </Typography>
          </Box>
        );
      },
    },
    { 
      field: 'businessDetail', 
      headerName: 'Business Details',headerAlign: 'center',flex:1,sortable:false,filterable:false,
      // valueGetter: (params) => {
      //   const panNumber = params.row.UserBusinessDetail?.panNumber || '';
      //   const aadharNumber = params.row.UserBusinessDetail?.aadharNumber || '';
      //   return `${panNumber} ${aadharNumber}`;
      // },
      renderCell: (params) => (
        <Box sx={{width:'95%',m:'auto'}}>
        {/* {params.row.UserBusinessDetail ? (
          
          // <Box sx={{textAlign:'center'}}>
            <Typography variant="body2" sx={{textAlign:'center',fontWeight:600}}>{params.row.UserBusinessDetail.businessName}</Typography>
            <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.UserBusinessDetail.businessEmail}</Typography>*/}
            {/* <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.Storefront? ''}</Typography> */}
          {/* </Box>
        // ) :  */}
        <Box sx={{display:'flex',gap:1,my:'5%',justifyContent:'flex-start'}}>
          <IconButton size='small' sx={{p:0}}>{(params.row.UserBusinessDetail?.panNumber || params.row.UserBusinessDetail?.aadharNumber)  ? <CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
          <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Business Profile</Typography>
          {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
          
        </Box>
        {/* // } */}
         <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
          <IconButton size='small' sx={{p:0}}>{params.row.ProviderServices.length>0?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
          <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Services {params.row.ProviderServices.length>0 && <Box component={'span'}>({params.row.ProviderServices.length})</Box>}</Typography>
          {/* <Typography variant='body2' sx={{}}>:</Typography> */}
         </Box>
         <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
          <IconButton size='small' sx={{p:0}}>{params.row.providerSlots.length>0 ?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
          <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Availability</Typography>
          {/* <Typography variant='body2' sx={{}}>:</Typography> */}
         </Box>
        <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
          <IconButton size='small' sx={{p:0}}>{params.row.Storefront?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
          <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Storefront</Typography>
          {/* <Typography variant='body2' sx={{}}>:</Typography> */}
         </Box>
      </Box>
      ),
    },
    {
      field: 'createdAt',headerName: 'Created At',headerAlign: 'center',flex:0.75,filterable:false,sortingOrder: ['asc', 'desc'],
      valueGetter: (params) => {
        return formatDateTime(params.row.createdAt);
      },
      renderCell: (params) => (
        <Typography variant="body2" sx={{width:'100%',textAlign:'center',color:'#3e3e3e',fontSize:'0.6rem'}}>{formatDateTime(params.row.createdAt)}</Typography>
      ),
    },
    {
      field: 'updatedAt',headerName: 'Updated At',headerAlign: 'center',flex:0.75,filterable:false,sortingOrder: ['asc', 'desc'],
      valueGetter: (params) => {
        return formatDateTime(params.row.updatedAt,'date');
      },
      renderCell: (params) => (
        <Typography variant="body2" sx={{width:'100%',textAlign:'center',color:'#3e3e3e',fontSize:'0.6rem'}}>{params.row.updatedAt?formatDateTime(params.row.updatedAt):''}</Typography>
      ),
    },
    {
        field: "actions", headerName: "Action",sortable:false,filterable: false,  flex:.5, renderCell: (params) => {

            return (
                <Stack spacing={2} direction="row" justifyContent={'center'} width={'100%'}>
                  {/* <PermissionGuard feature='service-expert' permission='canView'>
                    <RemoveRedEyeIcon
                        style={{
                            fontSize: "20px",
                            color: "blue",
                            cursor: "pointer",
                        }}
                        className="cursor-pointer"
                        // onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
                        onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
                    />
                  </PermissionGuard> */}
                    <PermissionGuard feature='service-expert' permission='canEdit'>
                      <BorderColorOutlinedIcon
                          style={{
                              fontSize: "20px",
                              color: "#ff3700",
                              cursor: "pointer",
                          }}
                          className="cursor-pointer"
                          // onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
                          onClick={(e) => window.open(`/admin/userProfile/${params.row.id}`, '_blank')}
                      />
                    </PermissionGuard>
                    {/* <DeleteIcon
                        style={{
                            fontSize: "20px",
                            color: "red",
                            cursor: "pointer",
                        }}
                        className="cursor-pointer"
                        onClick={(e) => deleteRow(params.row.id)}
                    /> */}
                </Stack>
            );
        },
        headerAlign: 'center',
    },
  ];

  // Initialize paginationModel state with potential localStorage backup
  const [paginationModel, setPaginationModel] = useState(
    // () => {
    // const savedState = localStorage.getItem('userListGridState');
    // return savedState 
    //   ? JSON.parse(savedState) 
    //   : 
      {
          page: 0,
          pageSize: 50,
          filters: {
            searchKey: "",
            // status: 1,
            sortBy:'updatedAt',
            sortType:'desc',
            filterOptions: [         
              {fieldName: "isTester", operator: "equals", value: 'false'}
            ],
          }
        // };
  });

   // State management
   const [allRowCount, setAllRowCount] = useState(0);
   const [list, setList] = useState([]);
   const [loading, setLoading] = useState(false);

  // // Effect to save grid state when paginationModel changes
  // useEffect(() => {
  //   if (apiRef.current) {
  //     const currentState = apiRef.current.exportState();
  //     localStorage.setItem('userListGridState', JSON.stringify({
  //       ...paginationModel,
  //       gridState: currentState
  //     }));

  //     handleStateChange({
  //       ...paginationModel,
  //       gridState: currentState
  //     })
  //   }
  // }, [paginationModel, apiRef]);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Effect to restore state when component mounts
  // useEffect(() => {
  //   const savedState = localStorage.getItem('userListGridState');
  //   if (savedState && apiRef.current) {
  //     const parsedState = JSON.parse(savedState);
      
  //     // Safely restore state with error handling
  //     try {
  //       if (parsedState.gridState) {
  //         // Validate the grid state before restoring
  //         const currentColumns = apiRef.current.getAllColumns();
  //         const savedColumns = parsedState.gridState.columns;
          
  //         // Check if saved columns match current columns
  //         const columnsMatch = currentColumns.length === savedColumns.length && 
  //           currentColumns.every((col, index) => col.field === savedColumns[index].field);
          
  //         if (columnsMatch) {
  //           apiRef.current.restoreState(parsedState.gridState);
  //         } else {
  //           console.warn('Saved grid state does not match current column configuration');
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error restoring grid state:', error);
  //       // Optionally clear the saved state if it's corrupted
  //       localStorage.removeItem('userListGridState');
  //     }
      
  //     // Restore paginationModel state
  //     setPaginationModel({
  //       page: parsedState.page,
  //       pageSize: parsedState.pageSize,
  //       filters: parsedState.filters
  //     });

  //     //set searchfield value accordingly
  //     setValue('searchKey', parsedState.filters.searchKey);
  //   }
  // }, [apiRef]);

//   useEffect(() => {
//     if (apiRef.current) {
//         try {
//             if (tableState.gridState) {
//                 // Validate the grid state before restoring
//                 const currentColumns = apiRef.current.getAllColumns();
//                 const savedColumns = tableState.gridState.columns;
                
//                 // Check if saved columns match current columns
//                 const columnsMatch = currentColumns.length === savedColumns.length && 
//                     currentColumns.every((col, index) => col.field === savedColumns[index].field);
                
//                 if (columnsMatch) {
//                     apiRef.current.restoreState(tableState.gridState);
//                 } else {
//                     console.warn('Saved grid state does not match current column configuration');
//                 }
//             }
            
//             // Restore paginationModel state
//             setPaginationModel({
//                 page: tableState.page,
//                 pageSize: tableState.pageSize,
//                 filters: tableState.filters
//             });

//             // Set search field value
//             setValue('searchKey', tableState.filters.searchKey);
//         } catch (error) {
//             console.error('Error restoring grid state:', error);
//         }
//     }
// }, [apiRef]);

// Handler for state changes
const handleStateChange = (newState) => {
  dispatch(updateExpertTableState({
      gridState: apiRef.current.exportState(),
      page: newState.page,
      pageSize: newState.pageSize,
      filters: newState.filters
  }));
};


  // Data fetching effect
  useEffect(() => {
    const getData = async () => {
      // Skip initial unnecessary fetch
      if (isInitialLoad) {
        setIsInitialLoad(false);
        return;
      }
  
      try {
        setLoading(true);
        const listResponse = await ProviderServices.fetchProviderList({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: {
            searchKey: paginationModel.filters.searchKey,
            // status: paginationModel.filters.status,
            sortBy: paginationModel.filters.sortBy,
            sortType: paginationModel.filters.sortType,
            filterOptions: paginationModel.filters.filterOptions || []            
          }
        });
        
        if (listResponse.success == '1') {
          console.log("no. of items:",listResponse.data.list?.length);
          setList(listResponse.data.list);

          setAllRowCount(listResponse.data.totalItems);
        } else {
          // console.log()
          // Swal.fire(listResponse.message, '', 'error');
          setList([]);
          setAllRowCount(0);
        }
      } catch (error) {
        Swal.fire(error.message, '', 'error');
      }
      setLoading(false);
    };
    getData();
  }, [paginationModel, isInitialLoad]);

  // Pagination change handler
  const handlePaginationChange = (newPagination) => {
    setPaginationModel(prev => ({
      ...prev,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    }));
  };

  
  // Form handling for filters
    const filterFormMethod = useForm();
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors },watch }= filterFormMethod;

  // Filter submission handler
  function onSubmit(data) {
    const filtersData = getValues();
    setPaginationModel(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        searchKey: filtersData.searchKey,
      }
    }));
  }

  // Clear filters handler
  const clearFilters = () => {
    reset();
    setPaginationModel(prev => ({
      ...prev,
      filters: {
        searchKey: "",
        // status: 1,
        sortBy:'createdAt',
        sortType:'desc',
        filterOptions: []
      }
    }));
    
    // Trigger clear for MultiColumnFilter
    setClearMultiFilter(true);

    // Reset the clear trigger after a short delay
    setTimeout(() => setClearMultiFilter(false), 100);
  };

    /************************  Table Column Manage **************************** */
   
    // State to track expanded rows
    const [expandedRowIds, setExpandedRowIds] = useState({});
            
    // Toggle expansion for expert categories of a specific row
    const toggleRowExpansion = (rowId) => {
      setExpandedRowIds(prev => ({
        ...prev,
        [rowId]: !prev[rowId]
      }));
    };

    /************************  Advance Filter **************************** */
    
  
    const [clearMultiFilter, setClearMultiFilter] = useState(false);
    
    const filterableColumns = [
      {
        key: 'profile',
        label: 'Profile',
        type: 'select',
        options: [
          { value: 'complete', label: 'complete' },
          { value: 'incomplete', label: 'in-complete' }
        ] 
      },
      {
        key: 'name',
        label: 'Name',
        type: 'text'
      },
      {
        key:'mobileNumber',
        label:'Mobile',
        type:'number'
      },
      {
        key:'email',
        label:'Email',
        type:'email'
      },
      {
        key: 'reviewStatus',
        label: 'Review Status',
        type: 'select',
        options: [
          { value: '0', label: 'Review Pending' },
          { value: '1', label: 'Approved' },
          { value: '2', label: 'Rejected' }
        ] 
      },
      {
        key: 'isActive',
        label: 'Account Status',
        type: 'select',
        options: [
          { value: 'true', label: 'Active' },
          { value: 'false', label: 'Inactive' }
        ]
      },
      {
        key: 'isKycVerified',
        label: 'KYC Verified',
        type: 'select',
        options: [
          { value: 'true', label: 'Verified' },
          { value: 'false', label: 'Not Verified' }
        ]
      },
      {
        key: 'isPanVerified',
        label: 'PAN Verified',
        type: 'select',
        options: [
          { value: 'true', label: 'Verified' },
          { value: 'false', label: 'Not Verified' }
        ]
      },
      {
        key: 'isAadhharVerified',
        label: 'Aadhaar Verified',
        type: 'select',
        options: [
          { value: 'true', label: 'Verified' },
          { value: 'false', label: 'Not Verified' }
        ]
      },
      {
        key: 'isVerified',
        label: 'Is Verified',
        type: 'select',
        options: [
          { value: 'true', label: 'Verified' },
          { value: 'false', label: 'Not Verified' }
        ]
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        type: 'date'
      },
      {
        key: 'isTester',
        label: 'Is Tester',
        type: 'select',
        options: [
          { value: 'true', label: 'True' },
          { value: 'false', label: 'False' }
        ]
      }
    ];

    const handleApplyFilter = (filterData) => {
      // Process filter data
      console.log(filterData);
      setPaginationModel({
        ...paginationModel,
        page: 0, // Reset to first page when applying new filters
        filters: {
          ...paginationModel.filters,
          // Convert MultiColumnFilter options to a format your backend can understand
        filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
        }
      });
    }
    


    /************* quick search related **************/
    const [quickFilterText, setQuickFilterText] = useState('');
    // Properly structured filter model
    const [filterModel, setFilterModel] = useState({
      items: [],
      quickFilterValues: []
    });

    // Update filter model when quick filter text changes
    useEffect(() => {
      setFilterModel(prevModel => ({
        ...prevModel,
        quickFilterValues: quickFilterText ? [quickFilterText] : []
      }));
    }, [quickFilterText]);

    /******************* Sorting model  ******************************/
    const [sortModel, setSortModel] = useState([
      {
        field: paginationModel.filters.sortBy,
        sort: paginationModel.filters.sortType,
      },
    ]);

    const handleSortingChange = (newSortModel)=>{
      console.log("new sort model",newSortModel);
      if (newSortModel && newSortModel.length > 0) {
        setSortModel(newSortModel);
        setPaginationModel(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            sortBy: newSortModel[0].field,
            sortType: newSortModel[0].sort
          }
        }));
      }
      // else{
      //   setSortModel([{
      //     field: 'createdAt',
      //     sort: 'asc',
      //   }]);
      //   setPaginationModel(prev => ({
      //     ...prev,
      //     filters: {
      //       ...prev.filters,
      //       sortBy: 'createdAt',
      //       sortType: 'asc'
      //     }
      //   }));
      // }
    }


  return (
      <>
        {loading && <Loading/>}
        <PageHeaderLayout headerTitle={'Manage Service Experts'}>
          <PermissionGuard feature='service-expert' permission='canCreate'>
            <Button variant='contained' onClick={() => navigate(-1)} size='small' endIcon={<ArrowBack />}>
                Back
            </Button>
          </PermissionGuard>
        </PageHeaderLayout>
          <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
            <FormProvider {...filterFormMethod}>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1} sx={{ p: '.5%' }}>
                      <Grid item xs={12} lg={2}>
                        <Controller
                          name="searchKey"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                          <TextField
                              {...field}
                              placeholder="Search by name,mobile,email "
                              // label="Search"
                              size="small"
                              fullWidth
                              sx={{
                                '& .MuiOutlinedInput-root': {                              
                                  paddingLeft: '0px',                              
                                }
                              }}
                              InputProps={{  // Changed from slotProps to InputProps
                                inputProps:{
                                  style:{
                                      height:'0.6rem',
                                      textTransform:'uppercase',   
                                      fontSize:'x-small'                       
                                  },
                                },
                                // startAdornment: (
                                //     // <InputAdornment position="start">
                                //     //   <IconButton>
                                //     //     <Search sx={{fontSize:'x-small'}}/>
                                //     //   </IconButton>
                                //     // </InputAdornment>
                                // ),
                              }}
                          />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Tooltip title="Search" sx={{ml:1,p:0}}>
                            <IconButton
                              size='small'
                              color="primary"
                              type="submit"
                              sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                              >
                              <SearchIcon sx={{fontSize:'.85rem'}} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear All Filters">
                            <IconButton
                              size='small'
                              color="primary"
                              sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                              onClick={clearFilters}
                            >
                            <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                            </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} lg={8} sx={{textAlign:'right'}}>
                        <Button variant='contained' onClick={() => navigate(`/admin/userProfile/0`)} size='small'  sx={{py:'2px'}} endIcon={<AddCircleIcon />}>
                            Add
                        </Button>
                      </Grid>
                  </Grid>
              </form>
            </FormProvider> 
          </Card>

        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',px:'1%' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <MultiColumnFilter
                  filterableColumns={filterableColumns}
                  onApplyFilter={handleApplyFilter}
                  externalClear={clearMultiFilter}
                  defaultFilterOptions={paginationModel.filters.filterOptions}
                />
                <ExportAll 
                  paginationModel={paginationModel} 
                  columns={columns} 
                />
              </Box>
              
              <QuickSearchTool filterModel={filterModel} setFilterModel={setFilterModel} />
              {/* <Box>
                <TextField
                  value={quickFilterText}
                  onChange={(event) => setQuickFilterText(event.target.value)}
                  placeholder="Search in Shown List..."
                  size="small"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: '0px',
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        height: '0.6rem',
                        // textTransform: 'uppercase',
                        fontSize: 'x-small'
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search sx={{fontSize:'x-small'}}/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box> */}
            </Box>
            <DataGrid
              // apiRef={apiRef}
              rows={list}
              columns={columns}
              rowCount={allRowCount}
              pageSizeOptions={[5, 10, 20, 50, 100]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={handlePaginationChange}
              filterModel={filterModel}
              

              sortingMode="server"
              sortModel={sortModel}                  
              onSortModelChange={handleSortingChange}
              disableMultiSort={true}

              loading={loading}
              disableColumnMenu
              disableRowSelectionOnClick
              slots={{
                // toolbar: GridToolbar,   
                loadingOverlay: Loading,
                noRowsOverlay: NoRowsLayout,
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              sx={{
                lineHeight:1,
                '& .MuiDataGrid-cell': {
                  border: 1,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: 'lightgrey',
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'center',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  borderTop: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  p:0,
                  height: 'fit-content',
                  minHeight:'0 !important',
                  height:'2rem'
                },
                '& .MuiDataGrid-columnHeader': {
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  '&:last-child': {
                    borderRight: 'none',
                  },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#3e3e3e'
                },
                '& .MuiDataGrid-row': {
                  '&:last-child .MuiDataGrid-cell': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  },
                  '& .MuiDataGrid-cell:last-child': {
                    borderRight: 'none',
                  },
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Box>
        </Box>
      </>
  )
}



// import { useState, useEffect, useCallback } from 'react';
// import { Card,Chip, Typography, Divider, Stack, TextField, Grid, Button, Box,Radio,RadioGroup,FormControlLabel, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, Badge, Avatar, Popover, InputAdornment } from '@mui/material';
// import Swal from 'sweetalert2';
// import Modal from '@mui/material/Modal';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { isMobile } from 'react-device-detect';
// import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid';
// import { User } from './UserService';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import { useLocation, useNavigate } from 'react-router-dom';
// import MenuItem from '@mui/material/MenuItem';
// import { formatDateTime } from '../../utils/dateUtils';
// import { useForm, FormProvider, Controller } from 'react-hook-form';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import SearchIcon from '@mui/icons-material/Search';
// import { ProviderServices } from './CrudServices';
// import NoRowsLayout from '../../components/Layout/NoRowsLayout';
// import { CustomerService } from '../Customer/Service';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
// import calculateAge from '../../utils/ageCalculation';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
// import { PermissionGuard } from '../../components/PermissionGuard';
// import { Helpers } from '../../services/Helpers';
// import { CheckCircle, Warning, Block, Pending, KeyboardArrowUp, KeyboardArrowDown, FilterList, Search } from '@mui/icons-material';
// import Loading from '../../components/Loader/Loading';
// import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
// import AdvancedFilter from './AdvancedFilter';
// import ExportAll from './filterAndExport/ExportAll';
// import MultiColumnFilter from '../../components/Filter/MultiColumnFilter';

// const style = {
//     position: 'absolute',
//     top: (isMobile) ? '80%' : '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: (isMobile) ? "95%" : "25%",
//     bgcolor: 'background.paper',
//     border: '2px solid #ddd',
//     boxShadow: 24,
//     p: 2,
// };

// const statusConfigs = [
//   {
//     label: 'Review Pending',
//     value: '0',
//     color: 'warning',
//     colorCode:'#ed6c02',
//     icon: <Pending sx={{ fontSize: '0.25rem' }} />,
//     tooltip: 'Account is under review'
//   },
//   {
//       label: 'Approved',
//       value: '1',
//       color: 'success',
//       colorCode:'#2e7d32',
//       icon: <CheckCircle sx={{ fontSize: '0.25rem' }} />,
//       tooltip: 'Account has been Approved Already'
//     },
//   {
//     label: 'Rejected',
//     value: '2',
//     color: 'error',
//     colorCode:'#d32f2f',
//     icon: <Warning sx={{ fontSize: '0.25rem' }} />,
//     tooltip: 'Account has been rejected'
//   }
// ];

// export default function UserList() {

//   // Grid API Reference
//   const apiRef = useGridApiRef();
//   const navigate = useNavigate();

//   /************************  Table Data State Manage **************************** */

//   const columns = [
//     // { field: 'id', headerName: 'ID', width: 70 ,headerAlign: 'center',},
//     { 
//       field: 'name', 
//       headerName: 'Profile', 
//       flex: 1.75,
//       headerAlign: 'center',
//       valueGetter: (params) => {
//         const id = params.row.id || '';
//         const firstName = params.row.UserProfile?.firstName || '';
//         const lastName = params.row.UserProfile?.lastName || '';
//         const email = params.row.UserProfile?.email || '';
//         const age = calculateAge(params.row.UserProfile?.dob) || '';
//         const mobile = params.row.mobileNumber || '';
//         const reviewStatus = statusConfigs[params.row.reviewStatus]?.label || '';
        
//         // Combine all profile information into a single searchable string
//         return `${firstName} ${lastName} ${email} ${age} ${mobile} ${id} ${reviewStatus}`.trim();
//       },
//       renderCell: (params) => (
        
          
//           <Box sx={{p: 1,display:'flex',justifyContent:'flex-start',width:'100%'}}>
//             <Stack
//               direction="column" 
//               spacing={1} 
//               // alignItems="center" 
//               justifyContent="flex-start"
//               sx={{ mt:1,width:'100%'}}
//             >
//               <Box sx={{display:'flex',gap:'5%' ,justifyContent:'flex-start',width:'100%'}}>
//                 <Badge color={params.row.isVerified?'success':'error'} badgeContent=" " variant='dot' sx={{display:'flex',alignItems:'center',justifyContent:'center',width:'fit-content',height:'fit-content'}}>
//                 <Avatar src={`${Helpers.ImagePathUrl()}/profile/sm/${params.row.UserProfile?.profileImage}`} />
//                 </Badge>
                
//                 <Box>
//                   <Typography 
//                     // onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
//                     onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
//                     variant="body2" 
//                     sx={{cursor:'pointer', fontSize:'.65rem',fontWeight:500,
//                       //on hover color
//                       '&:hover':{
//                         color:'warning.main'
//                       }
//                     }} 
//                     color={params.row.UserProfile?.firstName? '#1976d2' : '#d32f2f'}
//                   >
//                     {`${params.row.UserProfile?.firstName || ''} ${params.row.UserProfile?.middleName || ''} ${params.row.UserProfile?.lastName || ''}`}
//                     {!params.row.UserProfile?.firstName && 'Incomplete Profile'}
//                     <Box component={'span'} sx={{fontSize:'.55rem',fontWeight:600,color:'#382cd5'}}>({params.row.id})</Box>
//                   </Typography>
//                   {/* <Chip
//                     icon={<CheckCircle sx={{fontSize:'.75rem !important'}}/>}
//                     label={'Reviewed'}
//                     color={'success'}
//                     size="small"
//                     variant="outlined"
//                     sx={{fontSize:'xx-small',height:'fit-content'}}
//                   /> */}
//                   <Chip
//                     icon={statusConfigs[params.row.reviewStatus]?.icon}
//                     label={statusConfigs[params.row.reviewStatus]?.label}
//                     color={statusConfigs[params.row.reviewStatus]?.color}
//                     size="small"
//                     variant="outlined"
//                     sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
//                   />
//                   <Box>
//                     <Typography variant="caption" sx={{fontSize:'0.5rem'}}>{params.row.UserProfile?.email || 'N/A'}</Typography>
//                     <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Mobile:</Box>{` ${params.row.mobileNumber}`}</Typography>
//                     <Typography sx={{fontSize:'0.5rem'}}><Box component={'span'} sx={{fontWeight:600}}>Age: </Box>{`${calculateAge(params.row.UserProfile?.dob) || 'N/A'}`}</Typography>              
//                   </Box>
//                 </Box>
                
//               </Box>
//             </Stack> 
//           </Box>
//       ),
//     },
//     { 
//       field: 'status', 
//       headerName: 'Status', 
//       headerAlign: 'center',
//       filterable: false,
//       flex:.6,
//       valueGetter: (params) => {  
//         const isActive = params.row.isActive ? 'Active' : 'Inactive';
//         const isVerified = params.row.isVerified ? 'Verified' : 'non-verified';
//         return `${isActive} ${isVerified}`;
//       },
//       renderCell: (params) => (
//         <Box sx={{width:'max-content',m:'auto'}}>
//           <Chip 
//             label={params.row.isActive ? 'Active' : 'Inactive'} 
//             color={params.row.isActive ? 'success' : 'error'} 
//             size="small" 
//             sx={{fontSize:'0.5rem',height:'fit-content',py:'5%'}}
//         />
//         </Box>
//       ),
//     },
//     { 
//       field: 'verification', 
//       headerName: 'Verification', 
//       headerAlign: 'center',
//       filterable: false,
//       flex:.75,
//       valueGetter: (params) => {
//         const isKycVerified = params.row.isKycVerified ? 'KYC' : '';
//         const isPanVerified = params.row.isPanVerified ? 'PAN' : '';
//         const isAadhharVerified = params.row.isAadhharVerified ? 'Aadhaar' : '';
//         return `${isKycVerified} ${isPanVerified} ${isAadhharVerified}`;
//       },
//       renderCell: (params) => (
//         <Box sx={{width:'60%',m:'auto'}}>
//             {/*  */}
//             {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.panNumber)&& 
//               <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
//                   <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
//                   <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>PAN</Typography>
//                   {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
                  
//               </Box>
//             }
//             {(params.row.UserBusinessDetail && params.row.UserBusinessDetail?.aadharNumber) && <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
//                 <IconButton size='small' sx={{p:0}}><CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/></IconButton>
//                 <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>Aadhaar</Typography>
//                 {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
//             </Box>}
//             {(!params.row.UserBusinessDetail || 
//                   (params.row.UserBusinessDetail && 
//                   (!params.row.UserBusinessDetail.panNumber || params.row.UserBusinessDetail.panNumber.trim() === '') && 
//                   (!params.row.UserBusinessDetail.aadharNumber || params.row.UserBusinessDetail.aadharNumber.trim() === ''))
//                 ) && 
//               <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center'}}>
//                 <IconButton size='small' sx={{p:0}}><CancelIcon color='error' sx={{fontSize:'0.65rem'}}/></IconButton>
//                 <Typography variant="body2" sx={{fontSize:'0.5rem',width:'75%',fontWeight:'600',color:'#3e3e3e'}}>KYC</Typography>
//                 {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
//               </Box>
            
//             }
//         </Box>
//       ),
//     },
//     { 
//       field: 'categories', 
//       headerName: 'Categories', 
//       headerAlign: 'center',
//       filterable: false,
//       flex:1.25,
//       valueGetter: (params) => {
//         return params.row.providerCat.map(cat => cat.proServCat.title).join(', ');
//       },
//       renderCell: (params) => {
        
    
//         // Check if this specific row is expanded
//         const isRowExpanded = expandedRowIds[params.row.id];
    
//         return (
//           <Box sx={{width:'100%',display:'flex',alignItems:'center',flexWrap:'wrap',gap:'1%'}}>
//             {params.row.providerCat.length > 0 && params.row.providerCat.map((cat, index) => (
//               <Box key={index} >
//                 {/* Always show first 3 categories */}
//                 {index < 3 && (
//                   <>
//                     <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
//                     {/* <br/> */}
//                   </>
//                 )}
                
//                 {/* Show additional categories when row is expanded */}
//                 {isRowExpanded && index >= 3 && (
//                   <>
//                     <Chip label={cat.proServCat.title} size="small" sx={{my:'1%',fontSize:'0.5rem',height:'fit-content',py:'3%'}}/>
//                     {/* <br/> */}
//                   </>
//                 )}
//               </Box>
//             ))}
            
//             {/* Show more/less button if more than 4 categories */}
//             {params.row.providerCat.length > 4 && (
//               <Box sx={{width:'100%',textAlign:'end'}}>
//                 <Button 
//                   size='small' 
//                   sx={{fontSize:'.45rem'}} 
//                   onClick={() => toggleRowExpansion(params.row.id)}
//                 >
//                   {isRowExpanded ? 'view less' : `view ${params.row.providerCat.length-3} more`}
//                 </Button>
//               </Box>
//             )}
            
//             {/* Show N/A if no categories */}
//             <Typography sx={{
//               fontSize:'0.5rem',
//               textAlign:'center',
//               width:'100%'
//             }}>
//               {params.row.providerCat.length === 0 && 'N/A'}
//             </Typography>
//           </Box>
//         );
//       },
//     },
//     { 
//       field: 'businessDetail', 
//       headerName: 'Business Details',headerAlign: 'center',flex:1,filterable:false,
//       // valueGetter: (params) => {
//       //   const panNumber = params.row.UserBusinessDetail?.panNumber || '';
//       //   const aadharNumber = params.row.UserBusinessDetail?.aadharNumber || '';
//       //   return `${panNumber} ${aadharNumber}`;
//       // },
//       renderCell: (params) => (
//         <Box sx={{width:'95%',m:'auto'}}>
//         {/* {params.row.UserBusinessDetail ? (
          
//           // <Box sx={{textAlign:'center'}}>
//             <Typography variant="body2" sx={{textAlign:'center',fontWeight:600}}>{params.row.UserBusinessDetail.businessName}</Typography>
//             <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.UserBusinessDetail.businessEmail}</Typography>*/}
//             {/* <Typography variant="caption" sx={{textAlign:'center'}}>{params.row.Storefront? ''}</Typography> */}
//           {/* </Box>
//         // ) :  */}
//         <Box sx={{display:'flex',gap:1,my:'5%',justifyContent:'flex-start'}}>
//           <IconButton size='small' sx={{p:0}}>{(params.row.UserBusinessDetail?.panNumber || params.row.UserBusinessDetail?.aadharNumber)  ? <CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
//           <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Business Profile</Typography>
//           {/* <Typography variant='body2' sx={{flex:1}}>:</Typography> */}
          
//         </Box>
//         {/* // } */}
//          <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
//           <IconButton size='small' sx={{p:0}}>{params.row.ProviderServices.length>0?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
//           <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Services</Typography>
//           {/* <Typography variant='body2' sx={{}}>:</Typography> */}
//          </Box>
//          <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
//           <IconButton size='small' sx={{p:0}}>{params.row.providerSlots.length>0 ?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
//           <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Availability</Typography>
//           {/* <Typography variant='body2' sx={{}}>:</Typography> */}
//          </Box>
//         <Box sx={{display:'flex',gap:1,my:'5%',alignItems:'center',}}>
//           <IconButton size='small' sx={{p:0}}>{params.row.Storefront?<CheckCircleIcon color='success' sx={{fontSize:'0.65rem'}}/>:<CancelIcon color='error' sx={{fontSize:'0.65rem'}}/>}</IconButton>
//           <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'0.5rem'}}>Storefront</Typography>
//           {/* <Typography variant='body2' sx={{}}>:</Typography> */}
//          </Box>
//       </Box>
//       ),
//     },
//     {
//       field: 'createdAt',headerName: 'Created At',headerAlign: 'center',flex:1,filterable:false,
//       valueGetter: (params) => {
//         return formatDateTime(params.row.createdAt);
//       },
//       renderCell: (params) => (
//         <Typography variant="body2" sx={{fontSize:'0.5rem',fontWeight:600}}>{formatDateTime(params.row.createdAt)}</Typography>
//       ),
//     },
//     {
//         field: "actions", headerName: "Action",filterable: false,  flex:.5, renderCell: (params) => {

//             return (
//                 <Stack spacing={2} direction="row" justifyContent={'center'} width={'100%'}>
//                   {/* <PermissionGuard feature='service-expert' permission='canView'>
//                     <RemoveRedEyeIcon
//                         style={{
//                             fontSize: "20px",
//                             color: "blue",
//                             cursor: "pointer",
//                         }}
//                         className="cursor-pointer"
//                         // onClick={(e) => navigate(`/admin/expert-detail/${params.row.id}`)}
//                         onClick={(e) => window.open(`/admin/expert-detail/${params.row.id}`, '_blank')}
//                     />
//                   </PermissionGuard> */}
//                     <PermissionGuard feature='service-expert' permission='canEdit'>
//                       <BorderColorOutlinedIcon
//                           style={{
//                               fontSize: "20px",
//                               color: "#ff3700",
//                               cursor: "pointer",
//                           }}
//                           className="cursor-pointer"
//                           // onClick={(e) => navigate(`/admin/userProfile/${params.row.id}`)}
//                           onClick={(e) => window.open(`/admin/userProfile/${params.row.id}`, '_blank')}
//                       />
//                     </PermissionGuard>
//                     {/* <DeleteIcon
//                         style={{
//                             fontSize: "20px",
//                             color: "red",
//                             cursor: "pointer",
//                         }}
//                         className="cursor-pointer"
//                         onClick={(e) => deleteRow(params.row.id)}
//                     /> */}
//                 </Stack>
//             );
//         },
//         headerAlign: 'center',
//     },
//   ];

//   // Initialize paginationModel state with potential localStorage backup
//   const [paginationModel, setPaginationModel] = useState(() => {
//     const savedState = localStorage.getItem('userListGridState');
//     return savedState 
//       ? JSON.parse(savedState) 
//       : {
//           page: 0,
//           pageSize: 50,
//           filters: {
//             searchKey: "",
//             // status: 1,
//             sortBy:'createdAt',
//             sortType:'desc',
//             filterOptions: [],
//           }
//         };
//   });

//    // State management
//    const [allRowCount, setAllRowCount] = useState(0);
//    const [list, setList] = useState([]);
//    const [loading, setLoading] = useState(false);

//   // Effect to save grid state when paginationModel changes
//   useEffect(() => {
//     if (apiRef.current) {
//       const currentState = apiRef.current.exportState();
//       localStorage.setItem('userListGridState', JSON.stringify({
//         ...paginationModel,
//         gridState: currentState
//       }));
//     }
//   }, [paginationModel, apiRef]);

//   const [isInitialLoad, setIsInitialLoad] = useState(true);

//   // Effect to restore state when component mounts
//   useEffect(() => {
//     const savedState = localStorage.getItem('userListGridState');
//     if (savedState && apiRef.current) {
//       const parsedState = JSON.parse(savedState);
      
//       // Safely restore state with error handling
//       try {
//         if (parsedState.gridState) {
//           // Validate the grid state before restoring
//           const currentColumns = apiRef.current.getAllColumns();
//           const savedColumns = parsedState.gridState.columns;
          
//           // Check if saved columns match current columns
//           const columnsMatch = currentColumns.length === savedColumns.length && 
//             currentColumns.every((col, index) => col.field === savedColumns[index].field);
          
//           if (columnsMatch) {
//             apiRef.current.restoreState(parsedState.gridState);
//           } else {
//             console.warn('Saved grid state does not match current column configuration');
//           }
//         }
//       } catch (error) {
//         console.error('Error restoring grid state:', error);
//         // Optionally clear the saved state if it's corrupted
//         localStorage.removeItem('userListGridState');
//       }
      
//       // Restore paginationModel state
//       setPaginationModel({
//         page: parsedState.page,
//         pageSize: parsedState.pageSize,
//         filters: parsedState.filters
//       });

//       //set searchfield value accordingly
//       setValue('searchKey', parsedState.filters.searchKey);
//     }
//   }, [apiRef]);

//   // Data fetching effect
//   useEffect(() => {
//     const getData = async () => {
//       // Skip initial unnecessary fetch
//       if (isInitialLoad) {
//         setIsInitialLoad(false);
//         return;
//       }
  
//       try {
//         setLoading(true);
//         const listResponse = await ProviderServices.fetchProviderList({
//           page: paginationModel.page,
//           limit: paginationModel.pageSize,
//           filters: {
//             searchKey: paginationModel.filters.searchKey,
//             // status: paginationModel.filters.status,
//             sortBy: paginationModel.filters.sortBy,
//             sortType: paginationModel.filters.sortType,
//             filterOptions: paginationModel.filters.filterOptions || []            
//           }
//         });
        
//         if (listResponse.success == '1') {
//           setList(listResponse.data.list);
//           setAllRowCount(listResponse.data.totalItems);
//         } else {
//           Swal.fire(listResponse.message, '', 'error');
//         }
//       } catch (error) {
//         Swal.fire(error.message, '', 'error');
//       }
//       setLoading(false);
//     };
//     getData();
//   }, [paginationModel, isInitialLoad]);

//   // Pagination change handler
//   const handlePaginationChange = (newPagination) => {
//     setPaginationModel(prev => ({
//       ...prev,
//       page: newPagination.page,
//       pageSize: newPagination.pageSize
//     }));
//   };

  
//   // Form handling for filters
//     const filterFormMethod = useForm();
//     const { control, handleSubmit, reset, setValue, getValues, formState: { errors },watch }= filterFormMethod;

//   // Filter submission handler
//   function onSubmit(data) {
//     const filtersData = getValues();
//     setPaginationModel(prev => ({
//       ...prev,
//       filters: {
//         ...prev.filters,
//         searchKey: filtersData.searchKey,
//       }
//     }));
//   }

//   // Clear filters handler
//   const clearFilters = () => {
//     reset();
//     setPaginationModel(prev => ({
//       ...prev,
//       filters: {
//         searchKey: "",
//         // status: 1,
//         sortBy:'createdAt',
//         sortType:'desc',
//         filterOptions: []
//       }
//     }));
    
//     // Trigger clear for MultiColumnFilter
//     setClearMultiFilter(true);

//     // Reset the clear trigger after a short delay
//     setTimeout(() => setClearMultiFilter(false), 100);
//   };

//     /************************  Table Column Manage **************************** */
   
//     // State to track expanded rows
//     const [expandedRowIds, setExpandedRowIds] = useState({});
            
//     // Toggle expansion for expert categories of a specific row
//     const toggleRowExpansion = (rowId) => {
//       setExpandedRowIds(prev => ({
//         ...prev,
//         [rowId]: !prev[rowId]
//       }));
//     };

//     /************************  Advance Filter **************************** */
    
  
//     const [clearMultiFilter, setClearMultiFilter] = useState(false);
    
//     const filterableColumns = [
//       {
//         key: 'name',
//         label: 'Name',
//         type: 'text'
//       },
//       {
//         key:'mobileNumber',
//         label:'Mobile',
//         type:'number'
//       },
//       {
//         key:'email',
//         label:'Email',
//         type:'email'
//       },
//       {
//         key: 'reviewStatus',
//         label: 'Review Status',
//         type: 'select',
//         options: [
//           { value: '0', label: 'Review Pending' },
//           { value: '1', label: 'Approved' },
//           { value: '2', label: 'Rejected' }
//         ] 
//       },
//       {
//         key: 'isActive',
//         label: 'Account Status',
//         type: 'select',
//         options: [
//           { value: 'true', label: 'Active' },
//           { value: 'false', label: 'Inactive' }
//         ]
//       },
//       {
//         key: 'isKycVerified',
//         label: 'KYC Verified',
//         type: 'select',
//         options: [
//           { value: 'true', label: 'Verified' },
//           { value: 'false', label: 'Not Verified' }
//         ]
//       },
//       {
//         key: 'isPanVerified',
//         label: 'PAN Verified',
//         type: 'select',
//         options: [
//           { value: 'true', label: 'Verified' },
//           { value: 'false', label: 'Not Verified' }
//         ]
//       },
//       {
//         key: 'isAadhharVerified',
//         label: 'Aadhaar Verified',
//         type: 'select',
//         options: [
//           { value: 'true', label: 'Verified' },
//           { value: 'false', label: 'Not Verified' }
//         ]
//       },
//       {
//         key: 'isVerified',
//         label: 'Is Verified',
//         type: 'select',
//         options: [
//           { value: 'true', label: 'Verified' },
//           { value: 'false', label: 'Not Verified' }
//         ]
//       },
//       {
//         key: 'createdAt',
//         label: 'Created Date',
//         type: 'date'
//       }
//     ];

//     const handleApplyFilter = (filterData) => {
//       // Process filter data
//       console.log(filterData);
//       setPaginationModel({
//         ...paginationModel,
//         page: 0, // Reset to first page when applying new filters
//         filters: {
//           ...paginationModel.filters,
//           // Convert MultiColumnFilter options to a format your backend can understand
//         filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
//         }
//       });
//     }
    


//     /************* quick search related **************/
//     const [quickFilterText, setQuickFilterText] = useState('');
//     // Properly structured filter model
//     const [filterModel, setFilterModel] = useState({
//       items: [],
//       quickFilterValues: []
//     });

//     // Update filter model when quick filter text changes
//     useEffect(() => {
//       setFilterModel(prevModel => ({
//         ...prevModel,
//         quickFilterValues: quickFilterText ? [quickFilterText] : []
//       }));
//     }, [quickFilterText]);

//   return (
//       <>
//         {loading && <Loading/>}
//         <PageHeaderLayout headerTitle={'Manage Service Experts'}>
//           <PermissionGuard feature='service-expert' permission='canCreate'>
//             <Button variant='contained' onClick={() => navigate(`/admin/userProfile/0`)} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
//                 Add
//             </Button>
//           </PermissionGuard>
//         </PageHeaderLayout>
//           <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
//             <FormProvider {...filterFormMethod}>
//               <form onSubmit={handleSubmit(onSubmit)}>
//                   <Grid container spacing={1} sx={{ p: '.5%' }}>
//                       <Grid item xs={12} lg={2}>
//                         <Controller
//                           name="searchKey"
//                           control={control}
//                           defaultValue=""
//                           render={({ field }) => (
//                           <TextField
//                               {...field}
//                               placeholder="Search by name,mobile,email "
//                               // label="Search"
//                               size="small"
//                               fullWidth
//                               sx={{
//                                 '& .MuiOutlinedInput-root': {                              
//                                   paddingLeft: '0px',                              
//                                 }
//                               }}
//                               InputProps={{  // Changed from slotProps to InputProps
//                                 inputProps:{
//                                   style:{
//                                       height:'0.6rem',
//                                       textTransform:'uppercase',   
//                                       fontSize:'x-small'                       
//                                   },
//                                 },
//                                 // startAdornment: (
//                                 //     // <InputAdornment position="start">
//                                 //     //   <IconButton>
//                                 //     //     <Search sx={{fontSize:'x-small'}}/>
//                                 //     //   </IconButton>
//                                 //     // </InputAdornment>
//                                 // ),
//                               }}
//                           />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={12} lg={2}>
//                         <Tooltip title="Search" sx={{ml:1,p:0}}>
//                             <IconButton
//                               size='small'
//                               color="primary"
//                               type="submit"
//                               sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
//                               >
//                               <SearchIcon sx={{fontSize:'.85rem'}} />
//                             </IconButton>
//                         </Tooltip>
//                         <Tooltip title="Clear All Filters">
//                             <IconButton
//                               size='small'
//                               color="primary"
//                               sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
//                               onClick={clearFilters}
//                             >
//                             <RestartAltIcon sx={{fontSize:'.85rem'}}/>
//                             </IconButton>
//                         </Tooltip>
//                       </Grid>
//                   </Grid>
//               </form>
//             </FormProvider> 
//           </Card>

//         <Box sx={{ overflow: "auto" }}>
//           <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
//             <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',px:'1%' }}>
//               <Box sx={{ display: 'flex', gap: 1 }}>
//                 <MultiColumnFilter
//                   filterableColumns={filterableColumns}
//                   onApplyFilter={handleApplyFilter}
//                   externalClear={clearMultiFilter}
//                   defaultFilterOptions={paginationModel.filters.filterOptions}
//                 />
//                 <ExportAll 
//                   paginationModel={paginationModel} 
//                   columns={columns} 
//                 />
//               </Box>
//               <Box>
//                 <TextField
//                   value={quickFilterText}
//                   onChange={(event) => setQuickFilterText(event.target.value)}
//                   placeholder="Quick Search ..."
//                   size="small"
//                   fullWidth
//                   sx={{
//                     '& .MuiOutlinedInput-root': {
//                       paddingLeft: '0px',
//                     }
//                   }}
//                   InputProps={{
//                     inputProps: {
//                       style: {
//                         height: '0.6rem',
//                         // textTransform: 'uppercase',
//                         fontSize: 'x-small'
//                       },
//                     },
//                     startAdornment: (
//                       <InputAdornment position="start">
//                         <IconButton>
//                           <Search sx={{fontSize:'x-small'}}/>
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </Box>
//             </Box>
//             <DataGrid
//               apiRef={apiRef}
//               rows={list}
//               columns={columns}
//               rowCount={allRowCount}
//               pageSizeOptions={[5, 10, 20, 50, 100]}
//               paginationModel={paginationModel}
//               paginationMode="server"
//               onPaginationModelChange={handlePaginationChange}
//               filterModel={filterModel}
//               loading={loading}
//               disableColumnMenu
//               disableRowSelectionOnClick
//               slots={{
//                 // toolbar: GridToolbar,   
//                 loadingOverlay: Loading,
//                 noRowsOverlay: NoRowsLayout,
//               }}
//               autoHeight
//               getRowHeight={() => 'auto'}
//               sx={{
//                 lineHeight:1,
//                 '& .MuiDataGrid-cell': {
//                   border: 1,
//                   borderRight: 0,
//                   borderTop: 0,
//                   borderColor: 'lightgrey',
//                   display: 'flex',
//                   alignItems: 'center',
//                   // justifyContent: 'center',
//                 },
//                 '& .MuiDataGrid-columnHeaders': {
//                   borderBottom: '2px solid rgba(224, 224, 224, 1)',
//                   borderTop: '2px solid rgba(224, 224, 224, 1)',
//                   fontWeight: 600,
//                   fontSize: '0.75rem',
//                   p:0,
//                   height: 'fit-content',
//                   minHeight:'0 !important',
//                   height:'2rem'
//                 },
//                 '& .MuiDataGrid-columnHeader': {
//                   borderLeft: '1px solid rgba(224, 224, 224, 1)',
//                   '&:last-child': {
//                     borderRight: 'none',
//                   },
//                 },
//                 '& .MuiDataGrid-columnHeaderTitle': {
//                   fontWeight: 600,
//                   color: '#3e3e3e'
//                 },
//                 '& .MuiDataGrid-row': {
//                   '&:last-child .MuiDataGrid-cell': {
//                     borderBottom: '1px solid rgba(224, 224, 224, 1)',
//                   },
//                   '& .MuiDataGrid-cell:last-child': {
//                     borderRight: 'none',
//                   },
//                 },
//                 '& .MuiDataGrid-columnSeparator': {
//                   display: 'none',
//                 },
//               }}
//             />
//           </Box>
//         </Box>
//       </>
//   )
// }