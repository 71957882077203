import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import StorefrontIcon from '@mui/icons-material/Storefront';

import "../Home/home.css";
import CountUp from "react-countup";
import { useDispatch } from "react-redux";
import MainContentLayout from "../../components/Layout/MainContentLayout";
import { BookOnline, Dashboard, Group, Luggage, Person, TrendingDown, TrendingUp } from "@mui/icons-material";
import dayjs from "dayjs";
import TopServices from "./widgets/TopServices";
import TopCategories from "./widgets/TopCategories";
import RecentBookings from "./widgets/RecentBookings";
import SaleChart from "./widgets/SalesChart";
import { DashboardServices } from "./Service";



export default function Home() {
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'home'
        });
    }, []);

    const [topSectionData,setTopSectionData] = useState([
        {
            label:'New Booking',
            currentCount:'',
            previousCount:0,
            unit:'',
            icon:<BookOnline/>,
            isloading:true
        },
        {
            label:'New Customers',
            currentCount:'',
            previousCount:0,
            unit:'',
            icon:<Group/>,
            isloading:true
        },
        {
            label:'Average Daily Sales',
            currentCount:'',
            previousCount:0,
            unit:'₹',
            icon:<Luggage/>,
            isloading:true
        },
        {
            label:'New Service Experts',
            currentCount:'',
            previousCount:0,
            unit:'',
            icon:<Person/>,
            isloading:true
        },
        {
            label:'New StoreFronts',
            currentCount:'',
            previousCount:0,
            unit:'',
            icon:<StorefrontIcon/>,
            isloading:true
        }
    ])

    const fetchTopSectionData = async () => {
        try {
            const bookingCountResponse = await DashboardServices.bookingCount();
            if (bookingCountResponse.success=='1') {
                setTopSectionData((prevData) => {
                    return prevData.map((data, index) => {
                        if (index === 0) {
                            return { ...data, currentCount: bookingCountResponse.data.currentMonthBooking, previousCount: bookingCountResponse.data.previousMonthBooking,isloading:false
                            }
                        }
                        return data;
                    })
                })
            }
            const customerCountResponse = await DashboardServices.customerCount();
            if (customerCountResponse.success=='1') {
                setTopSectionData((prevData) => {
                    return prevData.map((data, index) => {
                        if (index === 1) {
                            return { ...data, currentCount: customerCountResponse.data.currentMonthCustomer, previousCount: customerCountResponse.data.previousMonthCustomer,isloading:false
                            }
                        }
                        return data;
                    })
                })
            }
            const storefrontCountResponse = await DashboardServices.storefrontCount();
            if (storefrontCountResponse.success=='1') {
                setTopSectionData((prevData) => {
                    return prevData.map((data, index) => {
                        if (index === 4) {
                            return { ...data, currentCount: storefrontCountResponse.data.currentMonthStorefronts, previousCount: storefrontCountResponse.data.previousMonthStorefronts,isloading:false
                            }
                        }
                        return data;
                    })
                })
            }
            const providerCountResponse = await DashboardServices.providerCount();
            if (providerCountResponse.success=='1') {
                setTopSectionData((prevData) => {
                    return prevData.map((data, index) => {
                        if (index === 3) {
                            return { ...data, currentCount: providerCountResponse.data.currentMonthExperts, previousCount: providerCountResponse.data.previousMonthExperts,isloading:false
                            }
                        }
                        return data;
                    })
                })
            }
            const monthlySaleCountResponse = await DashboardServices.monthlySaleCount();
            if (monthlySaleCountResponse.success=='1') {
                setTopSectionData((prevData) => {
                    return prevData.map((data, index) => {
                        if (index === 2) {
                            return { ...data, currentCount: monthlySaleCountResponse.data.currentMonthSale, previousCount: monthlySaleCountResponse.data.previousMonthSale,isloading:false
                            }
                        }
                        return data;
                    })
                })
            }
        } catch (error) {
            
        }
    }
    
    useEffect(() => {
        fetchTopSectionData();
    }, [])
    
    return (
        <>
            <MainContentLayout>             
                <Grid container spacing={1} sx={{mb:1}}>
                    <Grid item xs={12} >
                        <Stack spacing={1} direction="row">
                            {topSectionData.map((data,index)=>(
                                <Card sx={{ flex:1, height: 120 }} >
                                    <CardContent>
                                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                            <Typography gutterBottom variant="body2" component="div" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'.85rem'  }} >
                                                {data.label}
                                            </Typography>
                                            <Box sx={{color:'#2d2d2e'}}>
                                                {data.icon}
                                            </Box>
                                        </Box>
                                        <Box sx={{display:'flex',alignItems:'center'}}>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ color:`${data.currentCount<data.previousCount ?'red':'rgb(24, 131, 16)'}` }}>
                                                {data.unit}
                                                <CountUp delay={0.4} end={parseInt(data.currentCount)} duration={0.6} />
                                            </Typography>
                                            <Box component={'span'}>{data.currentCount<data.previousCount ? <TrendingDown sx={{color:"#ff0000"}}/>:<TrendingUp sx={{color:'rgb(24, 131, 16)'}}/>}</Box>
                                            {data.isloading && <CircularProgress size='1.5rem' color="inherit" sx={{color:'lightGray'}} />}
                                        </Box>
                                        
                                        <Typography gutterBottom variant="caption" component="div" sx={{  }} >
                                        vs Previous Month <Box component={'span'} sx={{fontWeight:500}}>{`(${parseInt(data.previousCount)})`}</Box>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Stack>
                    {/* </Grid>
                    <Grid item xs={12} lg={4} sx={{display:'flex',flexDirection:'row',gap:1}}> */}
                        
                    </Grid>
                </Grid>

                {/* =============second row================= */}
                <Grid container spacing={1} sx={{mb:1}}>                    
                    <Grid item xs={12} lg={8}>
                        <RecentBookings/>
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{display:'flex',flexDirection:'column',gap:1}}>
                        <TopServices/>
                    </Grid>
                </Grid>

                {/* =========== third row ============ */}
                <Grid container spacing={1} sx={{mb:1}}>
                    <Grid item lg={4} xs={12} sx={{display:'flex',flexDirection:'column',gap:1}}>
                        <TopCategories/>
                    </Grid>
                    <Grid item xs={12} lg={8}> 
                        <SaleChart />
                    </Grid>
                </Grid>
                
            </MainContentLayout>

        </>
    )
}
