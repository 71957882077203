import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useFormContext, Controller ,useForm} from 'react-hook-form';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const CoordinateDetails = (props) => {
  // const {addressMode} =props;
  const { watch,control, setValue, formState: { errors } } = useFormContext();
  const addressMode = watch('addressMode');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [googleServicesReady, setGoogleServicesReady] = useState(false);
  const loaded = useRef(false);

  // Load Google Maps script
  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }
      loaded.current = true;
    }
  }, []);

  // Check if Google services are ready
  useEffect(() => {
    const checkGoogleServices = setInterval(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        setGoogleServicesReady(true);
        clearInterval(checkGoogleServices);
      }
    }, 100);

    return () => clearInterval(checkGoogleServices);
  }, []);

  const services = useMemo(() => {
    if (!googleServicesReady) return { autocomplete: null, places: null };
    
    return {
      autocomplete: new window.google.maps.places.AutocompleteService(),
      places: new window.google.maps.places.PlacesService(document.createElement('div'))
    };
  }, [googleServicesReady]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (services.autocomplete) {
          services.autocomplete.getPlacePredictions(request, callback);
        }
      }, 400),
    [services.autocomplete],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (!services.autocomplete) {
      return undefined;
    }

    setLoading(true);

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch, services.autocomplete]);

  const handlePlaceSelect = (place) => {
    if (!place || !place.place_id || !services.places) return;

    services.places.getDetails(
      { placeId: place.place_id, fields: ['address_components', 'geometry', 'formatted_address'] },
      (placeResult, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = placeResult.address_components;
          const lat = placeResult.geometry.location.lat();
          const lng = placeResult.geometry.location.lng();

          setValue(`address`, placeResult.formatted_address,{shouldValidate:true});
          setValue(`latitude`, lat.toString(),{shouldValidate:true});
          setValue(`longitude`, lng.toString(),{shouldValidate:true});
          setValue(`state`, addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',{shouldValidate:true});
          setValue(`city`, addressComponents.find(c => c.types.includes('locality'))?.long_name || '',{shouldValidate:true});
          setValue(`pincode`, addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '',{shouldValidate:true});
        }
      }
    );
  };

  return (
    <Grid item xs={5} sx={{display:'flex',gap:2}}>
      <Controller
        name={`address-search`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Autocomplete
            id={`address-autocomplete`}
            sx={{ width: '100%' }}
            size='small'
            disabled={addressMode == 'select_address'}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.description : '');
              handlePlaceSelect(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Search here or enter the address manually'
                fullWidth
                error={!!error}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: '0.75rem',color:'#000' }, // Adjust size as needed
                  placeholder: 'Search here or enter the address manually',
                }}
                // helperText={error?.message}
              />
            )}
            renderOption={(props, option) => {
              const matches = option.structured_formatting.main_text_matched_substrings || [];
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [match.offset, match.offset + match.length]),
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />
    </Grid>
    
  );
}

export default CoordinateDetails;
