import React, { createContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BookingService } from './Service';
import dayjs from 'dayjs';
import BookingForm from './BookingForm';

const BookingDetailContext = createContext();
const AddBooking = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const bookingInitialData = {
        customer:'',
        addressMode:'select_address',
        selected_address:'',
        address:'',
        apartmentRoadArea:'',
        houseFlatBlockNo:'',
        landmark:'',
        city:'',
        latitude:'',
        longitude:'',
        pincode:'',
        state:'',
        serviceExpert: '',
        services:[],
        serviceDate:'',
        timeSlot:'',
        categoryId: '',
        paymentMode: '',       
        subCategoryId:'',  
        coinValue:0,      
    };
    const [bookingData,setBookingData] = useState(bookingInitialData);

    const [chargeDetails, setChargeDetails] = useState({
        additionalCharge:'0',
        vistingCharge:'0',
        waiveVisitCharge:'0',
    });
       
    const fetchBookingById = async ()=>{
        try {
            const bookingDetailResponse= await BookingService.bookingDetailById({bookingId:id});
            // console.log("booking details of selected booking for edit:",bookingDetailResponse);
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        if (id>0){
            fetchBookingById();
        }
    },[]);

    
  return (
    <BookingDetailContext.Provider value={{bookingData,setBookingData,bookingInitialData,chargeDetails, setChargeDetails}}>
        <Layout pageName={id>0 ? 'Edit Booking':'New Booking'}>        
            <Box sx={{ marginTop: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <BookingForm/>
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    </BookingDetailContext.Provider>
  );
}

export default AddBooking;
export {BookingDetailContext}
