import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CustomerAndLocation from './BookingStepWise/CustomerAndLocation';
import ServicesAndExpert from './BookingStepWise/ServicesAndExpert';
import ScheduleAndAddress from './BookingStepWise/ScheduleAndAddress';
import PaymentMethod from './BookingStepWise/PaymentMethod';
import { Button, Paper, StepButton, Typography } from '@mui/material';
import BookingReview from './BookingStepWise/BookingReview';
import { createContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BookingResponse from './BookingStepWise/BookingResponse';
import { BookingDetailContext } from './AddBooking';

const steps = [
  'Customer & Location',
  'Services & Expert',
  'Schedule',
  'Review',
  'Payment Method',
];
const chargeList = {
  couponId: '0',
  couponAmount: '0.00',
  discount: '0.00',
  platformCharges: '0.00',
}

const StepperContext = createContext();

// this helper function properly compares two service arrays, checking for: Array length changes,Service ID changes,Quantity changes,Service time changes

const areServicesEqual = (prev, curr) => {
    if (prev.length !== curr.length) return false;
    
    return prev.every((prevService, index) => {
      const currService = curr[index];
      return prevService.id === currService.id && 
             prevService.quantity === currService.quantity &&
             prevService.serviceTime === currService.serviceTime;
    });
  };

 const BookingForm=()=> {
    const {bookingData,setBookingData,bookingInitialData,chargeList} = useContext(BookingDetailContext);
    const navigate =  useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        setCompleted({
        ...completed,
        [activeStep]: true,
        });
        handleNext();
    };
    const setStepInComplete = () => {
        setCompleted({
        ...completed,
        [activeStep]: false,
        });
        // handleNext();
    };

    const handleReset = () => {
        setBookingData(bookingInitialData)
        setActiveStep(0);
        setCompleted({});
        
    };
    const handleStepClick=(index)=>{
        setActiveStep(index);
        // setStepInComplete();
    }

     //whenever latitute or longitude or pincode changes reset serviceExpert,timeSlot
     useEffect(()=>{
        if(bookingData.latitude && bookingData.longitude && bookingData.pincode){
            setBookingData({...bookingData,serviceExpert:'',services:[],timeSlot:'',serviceDate:''});
            // Reset completion status - only step 0 remains complete
            setCompleted({ 0: true });
            
            // Optionally, move back to step 1 if user is beyond it
            if (activeStep > 1) {
                setActiveStep(1);
            }
        }
    }
    ,[bookingData.latitude,bookingData.longitude,bookingData.pincode]);

    // if there is any chnages in services reset timeSlot
    // useEffect(()=>{
    //     if(bookingData.services.length>0){
    //         setBookingData({...bookingData,timeSlot:'',serviceDate:''});
    //         // Reset completion status - only step 1 remains complete
    //         setCompleted({ 0: true,1:true });

    //         // Optionally, move back to step 2 if user is beyond it
    //         if (activeStep > 2) {
    //             setActiveStep(2);
    //         }
    //     }
    // },[bookingData.services]);
    const [previousServices, setPreviousServices] = useState([]);
    useEffect(() => {
        if (bookingData.services.length > 0) {

            // console.log('previousServices:',previousServices);
            // console.log('bookingData.services:',bookingData.services);
          // Only proceed if there are actual changes in services
        //   console.log('is there any changes in services',!areServicesEqual(previousServices, bookingData.services)) ;
          if (!areServicesEqual(previousServices, bookingData.services)) {
            setBookingData(prev => ({
              ...prev,
              timeSlot: '',
              serviceDate: ''
            }));
            
            // Reset completion status - only step 1 remains complete
            setCompleted({ 0: true, 1: true });
    
            // Optionally, move back to step 2 if user is beyond it
            if (activeStep > 2) {
              setActiveStep(2);
            }
            // Update previous services
            setPreviousServices(bookingData.services);
          }
        } 
      }, [bookingData.services]);

    /*------------- After ConfirmBooking and handling response related----------*/ 
    const [bookingResponseDetails,setBookingResponseDetails] = useState({
        status:'',
        message:'',
        data:''
    })
  return (
    <StepperContext.Provider value={{activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset,bookingResponseDetails,setBookingResponseDetails,previousServices,setPreviousServices}}>
        <Paper sx={{ overflow: "auto",padding:{xs:'2%',md:'1% 0% 1% 0%'},mb:'.25%' }}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    // Find the highest completed step index
                    const lastCompletedStep = Math.max(-1, ...Object.keys(completed).map(Number));
                    // Step is clickable if it's completed or if it's the next step after the last completed one
                    const isClickable = completed[index] || index === lastCompletedStep + 1;
                    
                    return (
                        <Step key={label} completed={completed[index]}>
                            <StepLabel 
                                onClick={() => isClickable ? handleStep(index)() : null}
                                sx={{
                                    cursor:  'pointer',
                                    '&:hover': {
                                        opacity: isClickable ? 0.8 : 1,
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Paper>
        <Paper sx={{pt:'1%'}}>
        {allStepsCompleted() ? (
            <>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
                </Box> */}
                <BookingResponse/>
                {/* <Box sx={{textAlign:'center'}}>
                    <Button onClick={handleReset}>
                        New Booking
                    </Button>
                    <Button onClick={()=>navigate('/admin/bookings')}>
                        Finish
                    </Button>
                </Box> */}
            </>
            ) : (
                <>
                {activeStep === 0 && <CustomerAndLocation/>}
                {activeStep === 1 && <ServicesAndExpert/>}
                {activeStep === 2 && <ScheduleAndAddress/>}
                {activeStep === 3 && <BookingReview/>}
                {activeStep === 4 && <PaymentMethod/>}

                {/* {activeStep !== steps.length &&
                    (!completed[activeStep] && (
                    
                    <Button onClick={handleComplete}>
                        Complete Step
                    </Button>
                    ))} */}
                </>
            )
        }
        </Paper>
    </StepperContext.Provider>
  );
}

export default BookingForm
export {StepperContext}