import { Box, Chip, Paper, Switch, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ProviderServices } from '../CrudServices';

const IsTesterIndicator = ({isTester,fetchUserDetails}) => {
  const {id} = useParams();
  const handleStatusChange = async() => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: isTester?'Do you want to remove tester status?':'Do you want to make this user a tester?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    });
    if (result.isConfirmed) {
      const loading = Swal.fire({
        title: 'Please wait ...',
        didOpen: () => {
          Swal.showLoading()
        },
      });
      
      const response = await ProviderServices.modifyIsTesterStataus(id);
      if(response.success == '1'){
        loading.close();
        Swal.fire(
          'Changed!',
          'Tester status has been changed.',
          'success'
        );
        fetchUserDetails();        
      }else{
        loading.close();
        Swal.fire(
          'Failed!',
          'Failed to change tester status.',
          'error'
        );
      }
    }
  }
  return (
    <Paper sx={{ mt: 1,p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Chip
        label={'IsTester'}
        color="primary"
        variant="outlined"
        size="small" 
        sx={{fontSize:'0.5rem',height:'fit-content',py:'1%'}}
      />
      <Switch
        size='small'
        checked={isTester}
        onChange={()=>handleStatusChange()}
        sx={{
          '& .MuiSwitch-switchBase.Mui-checked': {
              color: 'success.main',
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: 'success.main'
          },
          '& .MuiSwitch-switchBase': {
              color: 'error.main',
          },
          '& .MuiSwitch-track': {
              backgroundColor: 'error.main'
          }
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography
        variant="body2"
        size="small"
        sx={{
          color: isTester ? 'success.main' : 'error.main',
          fontWeight: '600',
          fontSize:'.65rem'
        }}
      >
        {isTester ? 'True' : 'False'}
      </Typography>
    </Paper>
  );
}

export default IsTesterIndicator;
