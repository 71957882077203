import { Box, Card, Chip, Typography } from '@mui/material';
import React from 'react';

const PaymentDetails = ({selectedBookingDetails}) => {
  const paymentData = selectedBookingDetails?.payment;
  return (
    <Card>
        <Box sx={{ my:'2%',px:'2%',borderBottom:'1px solid #E0E0E0' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,py:'3%' }}>
                <Typography sx={{ fontWeight: '600',fontSize:'0.85rem' }}>{`Payment Summary`}</Typography>
            </Box>
        </Box>
        {/* <Box sx={{display:'flex',alignItems:'center',p:1}}>
          <Typography sx={{width:'40%',fontSize:'0.75rem',fontWeight:600}}>Payment Status</Typography>
          <Chip
            label={paymentData.status}
            size="small"
            sx={{
                // ml:1,
                fontSize:'0.75rem',
                fontWeight:'500',
                color:paymentData.status === 'PENDING' ? '#E8832A' :paymentData.status === 'COMPLETED'?'#009EB4':'default',
                bgcolor:paymentData.status === 'PENDING' ? '#FFEDD5' :paymentData.status === 'COMPLETED'?'#009eb442': 'default'
            }}
          />
        </Box> */}
        {selectedBookingDetails?.bookingStatus == 'completed' ?
          <>
            <Box sx={{display:'flex',alignItems:'center',p:1}}>
              <Typography sx={{width:'40%',fontSize:'0.75rem',fontWeight:600}}>Transaction No.</Typography>
              <Typography sx={{fontSize:'0.65rem'}}>: {paymentData?.transactionNumber}</Typography>
            </Box>
            <Box sx={{display:'flex',alignItems:'center',p:1}}>
              <Typography sx={{width:'40%',fontSize:'0.75rem',fontWeight:600}}>Payment Amount</Typography>
              <Typography sx={{fontSize:'0.65rem'}}>: ₹{parseFloat(paymentData?.amount).toFixed(2)}</Typography>
            </Box>
            <Box sx={{display:'flex',alignItems:'center',p:1}}>
              <Typography sx={{width:'40%',fontSize:'0.75rem',fontWeight:600}}>Payment Method </Typography>
              <Typography sx={{fontSize:'0.65rem'}}>: {paymentData?.paymentMethod}</Typography>
            </Box>
          </>
        :
          <Box sx={{minHeight:'25vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography>Payment Not Initiated</Typography>
          </Box>
      }
        
    </Card>
  );
}

export default PaymentDetails;
