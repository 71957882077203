import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Avatar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import { UserProfileContext } from '../UserProfile';
import { Helpers } from '../../../services/Helpers';
import bannerImage from '../../../assets/images/cudel_banner_1200x300.jpg'

const ImageSection = ({ imgModalOpen, setImgModalOpen, closeModal, imgData, imgUploadResponse, handlePImgTrig }) => {
  const {providerPersonalDetails, setProviderPersonalDetails} = useContext(UserProfileContext);
  const [coverImage, setCoverImage] = useState(bannerImage);

  const [profileImage, setProfileImage] = useState();
  const [imageCategory,setImageCategory]=useState(null)

  const openImageUploadModal = (imageType) => {
    // Update imgData with the current image type
    setImageCategory(imageType)
    setImgModalOpen(true);
  };

  const handleImageUpload = (uploadedImageData, imageType) => {
    if (imageType === 'cover') {
      setCoverImage(`${uploadedImageData.imageBaseUrl}${uploadedImageData.imageDirectory}md/${uploadedImageData.imageName}`);
    } else if (imageType === 'profile') {
      setProfileImage(`${uploadedImageData.imageBaseUrl}${uploadedImageData.imageDirectory}md/${uploadedImageData.imageName}`);
      // imgUploadResponse()
    }
    closeModal();
    // Call the parent component's image upload response handler
    imgUploadResponse(uploadedImageData,imageType);
  };
  useEffect(()=>{
    setProfileImage('')
  },[])
  return (
    <>
      <ImgUploadDialog
        open={imgModalOpen}
        onClose={closeModal}
        imgData={imgData}
        imgUploadResponse={(data) => handleImageUpload(data, imageCategory)}
        trigStatus={handlePImgTrig}
      />
      <Box sx={{ p: '3% 2%' }}>
        <Box sx={{ position: 'relative',boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
          <img
            src={coverImage}
            alt="Cover"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
          {/* <IconButton
            sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white' },
            }}
            onClick={() => openImageUploadModal('cover')}
          >
            <CameraAltIcon />
          </IconButton> */}
        </Box>
        <Box
          sx={{
            width: 'fit-content',
            position: 'relative',
            marginTop: -8,
            mx: 'auto'
          }}
        >
          <Avatar
            src={profileImage? profileImage : providerPersonalDetails.image && `${Helpers.ImagePathUrl()}/profile/sm/${providerPersonalDetails.image}`}
            alt="Profile"
            sx={{ width: 150, height: 150, border: '4px solid white' }}
          />
          <IconButton
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white' },
            }}
            onClick={() => openImageUploadModal('profile')}
          >
            <CameraAltIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default ImageSection;