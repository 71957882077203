import { AccountCircle, Assignment, Cancel, Chat, Close, CloseOutlined, Edit, HighlightOffRounded, PersonOutline } from '@mui/icons-material';
import { Box, Button, Card, Chip, Dialog, DialogContent, Fab, FormControl, Grid, IconButton, MenuItem, Paper, Rating, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ProviderServices } from '../Provider/CrudServices';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import { PermissionGuard } from '../../components/PermissionGuard';
import { isMobile } from 'react-device-detect';
import ChatBox from './ChatBox';
import PaymentDetails from './PaymentDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { BookingService } from './Service';
import MainContentLayout from '../../components/Layout/MainContentLayout';
import { useDispatch } from 'react-redux';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import Viewdetails from './Viewdetails';

dayjs.extend(customParseFormat);

const BookingDetails = () => {
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'bookings'
        });
    }, []);
    const { bookingId } = useParams();
    const navigate = useNavigate();
  return (
    <MainContentLayout>
        <PageHeaderLayout headerTitle={`Booking Detail's`} >
            <Button variant='contained' size='small' 
                onClick={()=>navigate('/admin/bookings')
                } 
                sx={{ marginTop: "10px", marginRight: "10px" }}
            >
                {'See Bookings List'}
            </Button>
        </PageHeaderLayout>    
        <Viewdetails bookingId={bookingId} />
    </MainContentLayout>
  );
}

export default BookingDetails;
