import React, { useContext, useEffect, useState } from 'react';
import {Button,  Typography,MenuItem,  Grid,  Box,Card, TextField, FormControl, Select, Chip, Paper, IconButton, Modal, InputAdornment} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatDateTime } from '../../../utils/dateUtils';
import { Close, Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import ChangeMobileNumber from './ChangeMobileNumber';
import { PermissionGuard } from '../../../components/PermissionGuard';

export default function BasicDetails({ personalDetails,fetchData,customerVerificationModal,setCustomerVerificationModal}) {
  
  const [providerPersonalDetails, setProviderPersonalDetails] = useState(
    {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      dob: '',
      mobileNumber:'',
      serviceCategories: [],
      waiveVisitCharge: 0,
      gender: '',
      image: '',
    }
  );
  useEffect(()=>{
    if (personalDetails) {
      // console.log("personal details:",personalDetails)
      setProviderPersonalDetails(pre=>({...pre,...personalDetails}))
    }
  },[personalDetails]);

  const [openMobileNumberChangeModal,setOpenMobileNumberChangeModal] = useState(false);
  const handleModalClose = async()=>{
    setOpenMobileNumberChangeModal(false);
    // onClose();
    fetchData();
  }

  const handleEdit = async()=>{
    const result = await Swal.fire({
      text: "Do you want to Change Customer Mobile Number now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
    if (result.isConfirmed) {
      setCustomerVerificationModal('change mobile number')
    }else{
      return false
    }
  }


  return (
    <>
      {/* ============= Change Mobile Number Otp  Modal =========== */}

      {/* <Modal
        open={openMobileNumberChangeModal}
        // onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
          <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Typography sx={{fontWeight:600,fontSize:'large'}}>Change Mobile Number</Typography>
            <Button size='small' variant='outlined' color='error' onClick={handleModalClose} startIcon={<Close/>} >close</Button>
          </Box>
          <ChangeMobileNumber customerData = {personalDetails} onCloseModel={handleModalClose}/>
        </Box>
      </Modal> */}
      <Grid container spacing={1}>
        {/* =======================Basic Info section================== */}
        <Grid item xs={12}>
          <Card>
            <Box sx={{p:'.5% 1%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Personal Info</Typography>
            </Box>
            <Box sx={{p:'1%'}}>
              <Box sx={{display:'flex',gap:'2%'}}>
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>First Name</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value={personalDetails?.profile?.firstName || ''}
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box>
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>Middle Name</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value={personalDetails?.profile?.middleName || ''}
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box>
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>Last Name</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value= {personalDetails?.profile?.lastName || ''}
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box>  
                
                {/* <Box sx={{my:'1%'}}>
                  <Typography sx={{width:'65%' ,fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number </Typography>
                    <TextField
                      // sx={{width:'58%'}}
                      size='small' 
                      value={providerPersonalDetails.mobileNumber}
                    />
                </Box> */}
              </Box>
              <Box sx={{display:'flex',gap:'2%',width:'100%'}}>
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>Date of Birth</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value={personalDetails?.profile?.dob && formatDateTime(personalDetails?.profile?.dob,'date') || ''}
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box>
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>Email</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value= {personalDetails?.profile?.email || ''}
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box> 
                <Box sx={{}}>
                  <Typography sx={{fontSize:'0.75rem' ,fontWeight:'600'}}>Mobile Number</Typography>
                  <TextField
                    sx={{}}
                    size='small'
                    disabled
                    value={personalDetails?.mobileNumber || ''}
                    InputProps={{
                      inputProps: {
                          style: {fontSize:'.75rem'}
                      },
                      endAdornment: (
                      <InputAdornment position="end">
                        <PermissionGuard feature="customer" permission="canEdit">
                          <IconButton size='small' color='warning' onClick={handleEdit}>
                            <Edit sx={{fontSize:'1rem'}} />
                          </IconButton>
                        </PermissionGuard>
                      </InputAdornment>
                      ),
                    }}
                  />                 
                </Box> 
              </Box> 
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Box sx={{p:'.5% 1%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Address Info</Typography>
            </Box>
            <Box sx={{p:'1%'}}>
              { personalDetails?.customerAllAddress&&personalDetails.customerAllAddress.length>0 ?
                personalDetails.customerAllAddress.map((address,index)=>(
                <Paper sx={{p:'2%',mb:'.5%'}} key={index} >
                  <Box sx={{display:'flex',alignItems:'center',mb:'1%',gap:'2%'}}>
                    <Typography fontWeight={'600'} sx={{fontSize:'small',color:'#778899'}}>{address.fullName}</Typography>
                    <Typography variant='caption' sx={{fontSize:'xx-small',bgcolor:'#d2d2d2',color:'#696969',textTransform:"uppercase",p:'.5% 1%',lineHeight:1.3,borderRadius:'.15rem'}}>{address.addressType=='1'?'Home':'Work'}</Typography>
                  </Box>
                  <Typography fontWeight={'500'} sx={{fontSize:'small',textTransform:'capitalize'}}>{address.address}</Typography>
                  <Typography fontWeight={'500'} sx={{fontSize:'small',mt:'1%'}}>{address.mobileNumber}</Typography>
                </Paper>
                ))
                :
                <Paper sx={{p:'2%'}}>
                  <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>No Address found</Typography>
                </Paper>
              }
            </Box>
            
          </Card>
        </Grid>
        {/* =======================address section================== */}
        {/* <AddressDetails/> */}
      </Grid>
    </>
  );
}
