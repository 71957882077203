import {Box,Button,Checkbox, FormControl,FormControlLabel,FormGroup , FormHelperText, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller,useForm } from 'react-hook-form'
import { BookingDetailContext } from '../AddBooking';
import { StepperContext } from '../BookingForm';
import Swal from 'sweetalert2';
import { BookingService } from '../Service';

const PaymentMethod = () => {
    const {bookingData,setBookingData,chargeList} = useContext(BookingDetailContext);
    const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset,bookingResponseDetails,setBookingResponseDetails} = useContext(StepperContext);
    const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
        mode: "onChange",
        defaultValues:{
            paymentMode:bookingData.paymentMode||'',
        }
    });
    // const completeStep4=(data)=>{
    //     // console.log(" submitted Services and Expert:",data);
    //     setBookingData(pre=>{
    //         return {...pre,paymentMode:data.paymentMode}
    //     })
    //     handleComplete();
    //     handleNext();
    // }
    const getFullAddress = () => {
        const addressParts = [
          bookingData.houseFlatBlockNo,
          bookingData.apartmentRoadArea,
          // bookingData.address,
          bookingData.landmark,
          bookingData.city,
          bookingData.state,
          bookingData.pincode
        ];
      
        // Filter out empty or undefined parts and join with a comma
        return addressParts
          .filter(part => part && part.trim() !== '')
          .join(', ');
      };
    const completeBooking=async()=>{
        // console.log(" submitted Schedule and Address:",data);
        // setBookingData(pre=>{
        //   return {...pre,...data}
        // })
        const result = await Swal.fire({
          text: "Do you want to Confirm Booking now?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Confirm now!',
          cancelButtonText: 'No, Cancel',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCloseButton: false,
        });
        if (result.isConfirmed){
          let subTotal =0;
          const items = bookingData?.services.map((service)=>{
            subTotal = parseFloat(subTotal)+parseFloat(service.price * service.quantity);
            return {
              providerServiceId: service.id,
              // serviceName: service.title,
              // price: service.price * service.quantity,
              quantity:service.quantity,
            }
          });
          const timeJson = JSON.parse(bookingData?.timeSlot);
          const fullAddress = getFullAddress();
          const formattedDatatobeSend = {
            customerId: bookingData?.customer.id,
            providerId: bookingData?.serviceExpert,
            paymentId: 1,
            serviceDate: bookingData?.serviceDate,
            bookingStartTime: timeJson.startTime,
            bookingEndTime: timeJson.endTime,
            subTotal: parseFloat(subTotal).toFixed(2),
            coinValue:bookingData?.coinValue || 0,
            couponId: chargeList?.couponId,
            couponAmount: chargeList?.couponAmount,
            discount: chargeList?.discount,
            platformCharges: chargeList?.platformCharges,
            total: parseFloat(subTotal).toFixed(2),  
            callbackRequest: true,
            add_customerFullName: `${bookingData?.customer.profile.firstName} ${bookingData?.customer.profile.middleName} ${bookingData?.customer.profile.lastName}`,
            add_mobileNumber: bookingData?.customer.mobileNumber,
            add_pincode: bookingData?.pincode,
            add_latitude: bookingData?.latitude,
            add_longitude: bookingData?.longitude,
            add_city: bookingData?.city,
            add_state: bookingData?.state,
            add_landmark: bookingData?.landmark||'',
            add_address: bookingData.addressMode =='select_address'? bookingData?.address : fullAddress,
            items: items,
          }
          console.log("data to be send for booking:",formattedDatatobeSend);
    
          try {
            // Show loading state
            Swal.fire({
              title: 'Processing...',
              text: 'Please wait while we processing your booking request',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
      
            // Make the API call
            const bookingResponse = await BookingService.addBooking(formattedDatatobeSend);
            // console.log("new booking Response:",bookingResponse);
      
            // Close loading dialog and show success/error message
            if (bookingResponse.success === 1) {
              // await fetchProviderServiceList({
              //   providerId:serviceDetailsData.providerId,
              //   page:controller.page+1, 
              //   pageSize:controller.pageSize,
              // }); 
              setBookingResponseDetails({status:'true',message:'booking successfull',data:bookingResponse.data})
              await Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: bookingResponse.message || 'Service Booking successfull',
                timer: 1500
              });
              handleComplete();
              handleNext();
              return true;
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Something Went Wrong ... Booking failed'
              });
              return false;
            }
      
          } catch (error) {
            // console.error("Error in Booking:", error);
            await Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'An error occurred while booking'
            });
            return false;
          }
        }else {
          return false;
        }
      }
  return (
    <Box component={'form'} onSubmit={handleSubmit(completeBooking)} sx={{pb:'1%'}}>
        <Grid container spacing={2} >
            <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
                <Grid container spacing={2} >
                {/* ............left section................... */}
                    <Grid item xs={12} lg={5}>
                        <Grid container>
                            <Grid item xs={12} sx={{my:'1%'}}>
                                <Grid container sx={{mb:'1%'}}>
                                    <Grid item lg={7} xs={12}>
                                        <Typography sx={{fontWeight:'600'}}>Payment Method :</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} xs={12}>
                                    <Controller
                                        name="paymentMode"
                                        control={control}
                                        rules={{ required: "Please select paymentMode" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl sx={{ minWidth: "100%" }} component="fieldset" error={!!error}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={field.value === 'pay after service'}
                                                                onChange={(e) => {
                                                                    // If checkbox is checked, set to 'pay after service'
                                                                    // If unchecked, set to empty string
                                                                    const newValue = e.target.checked ? 'pay after service' : '';
                                                                    field.onChange(newValue);
                                                                    setValue('paymentMode', newValue);
                                                                }}
                                                            />
                                                        }
                                                        label="Pay After Service"
                                                    />
                                                </FormGroup>
                                                {error && <FormHelperText>{error.message}</FormHelperText>}
                                            </FormControl>
                                        )}
                                    />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* =======Form Action Button Section ========*/}
        <Box sx={{display:'flex',justifyContent:'center',gap:2,mt:'.5%'}}>
            <Button disabled={activeStep === 0} onClick={handleBack} variant='contained' color='secondary'>Back</Button>
            <Button 
                disabled={!watch('paymentMode') || !isDirty} 
                type='submit' 
                // onClick={handleNext}
                variant='contained' color='primary'
            >
                {/* Submit & Next */}
                confirm Booking
            </Button>
        </Box>
    </Box>
  );
}

export default PaymentMethod;
