import { useState, useEffect } from 'react';
import {Card,Chip, Typography, Stack, TextField, Grid, Button, Box, Tooltip, IconButton, Switch, Avatar, Paper, InputAdornment } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid} from '@mui/x-data-grid';
import {useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { CustomerService } from './Service';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { PermissionGuard } from '../../components/PermissionGuard';
import { CheckCircle, Cancel, Close, BorderColorOutlined, Search, } from '@mui/icons-material';
import PersonalDetails from './PersonalDetail';
import calculateAge from '../../utils/ageCalculation';
import Loading from '../../components/Loader/Loading';
import ExportAll from './ExportAll';
import MultiColumnFilter from '../../components/Filter/MultiColumnFilter';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import { ArrowBack } from '@mui/icons-material';


export default function CustomerList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  /************************  Table Column Manage **************************** */


  const columns = [
    // { field: "id", headerAlign: 'center', headerName: "ID", width: 100, editable: false,filterable:false, },
    {field: "name", headerAlign: 'center', headerName: "Name", flex: 2, editable: false,
      
      
      valueGetter: (params) => {
        const firstName = params.row?.profile?.firstName || '';
        const middleName = params.row?.profile?.middleName || '';
        const lastName = params.row?.profile?.lastName || '';
        const email = params.row?.profile?.email || '';
        const verification = params.row?.isVerified ? 'Verified' : 'non-verified';
        
        // Combine all profile information into a single searchable string
        return `${firstName} ${middleName} ${lastName} ${email}  ${verification}`.trim();
      },
      renderCell: (params) => (
        <Box sx={{p: 1,display:'flex',justifyContent:'flex-start',width:'100%'}}>
          <Stack
            direction="column" 
            spacing={1} 
            // alignItems="center" 
            justifyContent="flex-start"
            sx={{ mt:1,width:'100%'}}
          >
            <Box sx={{display:'flex',gap:'5%' ,justifyContent:'flex-start',width:'100%'}}>
              <Avatar alt={params.row?.profile?.firstName+'_image'} src={''} 
                  // src={`${Helpers.ImagePathUrl()}/profile/sm/${params.row.profile?.profileImage}`} 
              />
              <Box>
                <Typography variant="body2"                   
                  onClick={(e) => window.open(`/admin/customerProfile/${params.row.id}`, '_blank')}
                  sx={{cursor:'pointer', fontSize:'.65rem',fontWeight:500,
                    //on hover color
                    '&:hover':{
                      color:'warning.main'
                    }
                  }} 
                  color={params.row.profile?.firstName? '#1976d2' : '#d32f2f'}
                >
                  {`${params.row.profile?.firstName || ''} ${params.row.profile?.middleName || ''} ${params.row.profile?.lastName || ''}`}
                  {!params.row.profile?.firstName && 'Incomplete Profile'}
                  <Box component={'span'} sx={{fontSize:'.55rem',fontWeight:600,color:'#382cd5'}}>({params.row.id})</Box>
                </Typography>
                <Chip
                  icon={params.row?.isVerified ? <CheckCircle fontSize="small" /> : <Cancel fontSize="small" />}
                  label={params.row?.isVerified ? "Verified" : "Not Verified"}
                  color={params.row?.isVerified ? "success" : "error"}
                  size="small"
                  variant="outlined"
                  sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
                />
                <Box>
                  <Typography variant="caption" sx={{fontSize:'0.5rem'}}>{params.row.profile?.email || 'N/A'}</Typography>
                  {/* <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Mobile:</Box>{` ${params.row.mobileNumber}`}</Typography> */}
                  <Typography sx={{fontSize:'0.5rem'}}><Box component={'span'} sx={{fontWeight:600}}>Age: </Box>{`${calculateAge(params.row.profile?.dob) || 'N/A'}`}</Typography>              
                </Box>
              </Box>
              
            </Box>
          </Stack> 
        </Box>
      ),
    },
    { field: "gender", headerAlign: 'center', headerName: "Gender", flex: 1, editable: false,
      valueGetter: (params) => {
        return `${params.row.profile.gender}`;
      },
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'.65rem',fontWeight:500 }}>
          {params.row.profile.gender}
        </Typography>
      ),
    },
    { field: "mobileNumber", headerAlign: 'center', headerName: "Mobile Number", flex: 1, editable: false, 
      
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'.65rem',fontWeight:500 }}>
          {params.row.mobileNumber}
        </Typography>
      ),
    },
    {
      field: "isActive",
      headerAlign: 'center',
      headerName: "Account",
      flex: 1,
      editable: false,
      
      // Define valueGetter for filtering
      valueGetter: (params) => {
        if (params.row.isActive) {
          return 'active,true';
        }
        return 'inactive,false';
      },
      renderCell: (params) => {
        return(       
        <PermissionGuard feature='register-admin' permission='canEdit'>
          <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <Switch
                size='small'
                checked={params.row.isActive}
                onChange={()=>handleStatusChange({isActive: !params.row?.isActive},params.row.id)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'success.main',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: 'success.main'
                  },
                  '& .MuiSwitch-switchBase': {
                      color: 'error.main',
                  },
                  '& .MuiSwitch-track': {
                      backgroundColor: 'error.main'
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                variant="body2"
                size="small"
                sx={{
                  color: params.row?.isActive ? 'success.main' : 'error.main',
                  fontWeight: '600',
                  fontSize:'.65rem'
                }}
              >
                {params.row?.isActive ? 'Active' : 'Inactive'}
              </Typography>
            </Box>
          </Box>
        </PermissionGuard>
        
      )},
    },    
    {
      field: 'createdAt',headerName: 'Created At',headerAlign: 'center',flex:1,filterable:false,
      valueGetter: (params) => {
        return formatDateTime(params.row.createdAt);
      },
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'0.5rem',fontWeight:600}}>{formatDateTime(params.row.createdAt)}</Typography>
      ),
    },
    {field: 'action', headerAlign: 'center', headerName: 'Action', sortable: false, flex: 1,filterable:false,
      renderCell: (params) => {
        const data = params.row;
        const handleEdit = async()=>{
          const result = await Swal.fire({
            text: "Do you want to Edit Customer Detail now?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Edit now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
          });
          if (result.isConfirmed) {
            setSelectedCustomerDetail({
              id: params.row.id,
              firstName: params.row.profile.firstName,
              middleName: params.row.profile.middleName,
              lastName: params.row.profile.lastName,
              mobileNumber: params.row.mobileNumber,
              gender: params.row.profile.gender,
              email: params.row.profile.email,
              dob: params.row.profile.dob
            });
            setOpenAddEditModal(true)
          }else{
            return false
          }
        }
        return (
          <Stack direction="row" spacing={1} alignItems={'center'} sx={{textAlign:'justify'}}>
            <PermissionGuard feature={'customer'} permission='canEdit'>
              <IconButton color='info' 
                size='small'
                onClick={handleEdit}  
              ><BorderColorOutlined sx={{fontSize:'0.95rem'}}/></IconButton>
            </PermissionGuard>
            <PermissionGuard feature={'customer'} permission='canDelete'>
              <IconButton color='error' size='small'
              onClick={()=>handleDeleteCustomer(params.row.id)}
                // onClick={(event)=>handleClick(event,params.row)}  
              ><DeleteForeverIcon sx={{fontSize:'0.95rem'}}/></IconButton>
            </PermissionGuard>
          </Stack>
        );
      },
    },
  ];
  
  /************************  Table Data State Manage **************************** */
  const [allRowCount, setAllRowCount] = useState(0);
  const [rowdata, setRowData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
    filters: {
      searchKey: "",
      // status: 1,
      sortKey:'',
      sortDirection:'desc',
      filterOptions: [
        {fieldName: "isTester", operator: "equals", value: 'false'}
      ]
    }
  });
  const handlePaginationChange = (newPagination) => {
    setPaginationModel({
      ...paginationModel,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    });
  }

  const fetchCustomerList = async () => {
    try {
      setLoading(true)
      const listResponse = await CustomerService.sortedCustomerList({
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        filters: {
          searchKey: paginationModel.filters.searchKey,   // Use the title filter from the paginationModel
          // status: paginationModel.filters.status,
          sortKey:paginationModel.filters.sortKey,
          sortDirection:paginationModel.filters.sortDirection,
          filterOptions: paginationModel.filters.filterOptions || []
        }
      });
      // console.log("customer list Response:",listResponse)
      // setValue('status', paginationModel.filters.status)
      if (listResponse.success) {
        setRowData(listResponse.data.customers);
        setAllRowCount(listResponse.data.totalItems);
      } 
    } catch (error) {
      Swal.fire(error.message,'','error')
    }
    setLoading(false)
  };
  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    
    fetchCustomerList();
  }, [paginationModel]);

  /*............Server side Searching And Filter realted.................... */

  const { control, handleSubmit, reset, setValue, getValues, formState: { errors },watch } = useForm({
    mode: "onChange"
  });

  function onSubmit(data) {
    const filtersData = getValues();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: filtersData.searchKey,
      }
    });
  }
  const clearFilters = () => {
    reset();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: "",
        sortKey:'createdAt',
        sortDirection:'desc',
        filterOptions: []
      }
    });
    
    // Trigger clear for MultiColumnFilter
    setClearMultiFilter(true);

    // Reset the clear trigger after a short delay
    setTimeout(() => setClearMultiFilter(false), 100);

  }

  // useEffect(()=>{
  //   if(watch('searchKey').length>2){
  //     setPaginationModel({
  //       ...paginationModel,
  //       filters: {
  //         ...paginationModel.filters,
  //         searchKey:watch('searchKey'),
  //       }
  //     });
      
  //   }
  // },[watch('searchKey')])
  
  const handleStatusChange = async (data,customerId) => {
    // console.log ("required customer data for status change:",data);
    const result = await Swal.fire({
      text: "Do you want to Change Account Status now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we update the account status',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const statusChangeResponse = await CustomerService.updateCustomerStatus(data,customerId);
        // console.log("customer account status change response:", statusChangeResponse);
  
        // Close loading dialog and show success/error message
        if (statusChangeResponse.success === 1) {
          await fetchCustomerList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: statusChangeResponse.message || 'Account Status updated successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: statusChangeResponse.message || 'Failed to update account status'
          });
          return false;
        }
  
      } catch (error) {
        // console.error("Error changing customer account status:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while updating the account status'
        });
        return false;
      }
    } else {
      return false;
    }
  };
  const handleDeleteCustomer = async (customerId) => {
    const result = await Swal.fire({
      text: "Do you want to Delete Customer Account now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while deleting customer account',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const customerDeleteResponse = await CustomerService.deleteCustomerById(customerId);
        // console.log("customer account delete response:", customerDeleteResponse);
  
        // Close loading dialog and show success/error message
        if (customerDeleteResponse.success === 1) {
          await fetchCustomerList();
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: customerDeleteResponse.message || 'Account Deleted successfully',
            timer: 1500
          });
          
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: customerDeleteResponse.message || 'Failed to delete account'
          });
          return false;
        }
  
      } catch (error) {
        // console.error("Error deleting customer account :", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while deleting the account '
        });
        return false;
      }
    } else {
      return false;
    }
  };
  
  /************ customer mobile/otp verification related ***********/
    const [selectedCustomerDetail,setSelectedCustomerDetail] = useState(null);
    
    /************* Add/Edit cuistomer Related *************/
    const [openAddEditModal,setOpenAddEditModal] =  useState(false);
    const handleAddEditCustomerModal = async ()=>{
      fetchCustomerList();
      setSelectedCustomerDetail(null);
      setOpenAddEditModal(false);
    }

    /************* quick search related **************/
    const [quickFilterText, setQuickFilterText] = useState('');
    // Properly structured filter model
    const [filterModel, setFilterModel] = useState({
      items: [],
      quickFilterValues: []
    });

    // Update filter model when quick filter text changes
    useEffect(() => {
      setFilterModel(prevModel => ({
        ...prevModel,
        quickFilterValues: quickFilterText ? [quickFilterText] : []
      }));
    }, [quickFilterText]);

    /**** Advanced multi column filter related ****/
    const [clearMultiFilter, setClearMultiFilter] = useState(false);
    const filterableColumns = [
      {
        key: 'name',
        label: 'Customer Name',
        type: 'text'
      },
      {
        key: 'mobileNumber',
        label: 'Mobile Number',
        type: 'number'
      },
      {
        key:'email',
        label:'Email',
        type:'email'
      },
      // {
      //   key:'age',
      //   label:'Age',
      //   type:'number'
      // },
      {
        key: 'gender',
        label: 'Gender',
        type: 'select',
        options: [
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
          { value: 'Prefer Not To Say', label: 'Other' }
        ]
      },
      {
        key: 'isActive',
        label: 'Account',
        type: 'select',
        options: [
          { value: 'true', label: 'Active' },
          { value: 'false', label: 'Inactive' }
        ]
      },
      {
        key: 'isVerified',
        label: 'Is Verified',
        type: 'select',
        options: [
          { value: 'true', label: 'Verified' },
          { value: 'false', label: 'Not Verified' }
        ]
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        type: 'date'
      },
      {
        key: 'isTester',
        label: 'Is Tester',
        type: 'select',
        options: [
          { value: 'true', label: 'True' },
          { value: 'false', label: 'False' }
        ]
      }
    ];
    const handleApplyFilter = (filterData) => {
      // Process filter data
      console.log(filterData);
      setPaginationModel({
        ...paginationModel,
        page: 0, // Reset to first page when applying new filters
        filters: {
          ...paginationModel.filters,
          // Convert MultiColumnFilter options to a format your backend can understand
        filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
        }
      });
    }

  return (
    <>
      <PageHeaderLayout headerTitle={'Manage Customers'}>
        <Button size='small' variant='contained' onClick={() => navigate(-1)} endIcon={<ArrowBack sx={{fontSize:'.85rem!important'}}/>} sx={{ height: "30px", marginTop: "10px" }}>
          Back
        </Button>
      </PageHeaderLayout>
      <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
        <Grid container>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ p: '.5%' }}>
                <Grid item xs={12} lg={5}>
                  <Controller
                    name="searchKey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Search by name,mobile,email........"
                        // label="Search"
                        size="small"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {                            
                              paddingLeft: '0px',                            
                          }
                        }}
                        InputProps={{  // Changed from slotProps to InputProps
                          inputProps:{
                            style:{
                                height:'0.6rem',
                                // textTransform:'uppercase',   
                                fontSize:'x-small'                       
                            },
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <Search sx={{fontSize:'x-small'}}/>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Tooltip title="Search">
                    <IconButton
                      size='small'
                      color="primary"
                      type="submit"
                      sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                      >
                      <SearchIcon sx={{fontSize:'.95rem'}} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear All Filters">
                    <IconButton
                        size='small'
                        color="primary"
                        sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                        onClick={clearFilters}
                    >
                      <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={1}/>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '1%' }}>
            <PermissionGuard feature="customer" permission="canCreate">
              <Button 
                variant='contained' 
                // onClick={() => navigate(`/admin/customerProfile/0`)} 
                onClick={()=>setOpenAddEditModal(true)}              
                sx={{ height:'fit-content',fontSize:'x-small',p:'2% 1.25%'}}  
                endIcon={<AddCircleIcon />}
              >
                Add
              </Button>
            </PermissionGuard>
          </Grid>
        </Grid>
      </Card>
        {/* ================Customer list Table ======================== */}
      <Card>
        <Box sx={{ overflow: "auto" }}>
          <Box 
            sx={{ 
              width: "100%", display: "table",  tableLayout: "fixed", 
              backgroundColor: "#fff", borderRadius: '.5rem',
              '& .odd': {
                bgcolor: 'white',
              },
              '& .even': {
                bgcolor: '#ECECEC',
              },
            }}>
      
            {loading && <Loading/>}
            {/* custom toolbar */}
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',px:'1%' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <MultiColumnFilter
                  filterableColumns={filterableColumns}
                  onApplyFilter={handleApplyFilter}
                  externalClear={clearMultiFilter}
                  defaultFilterOptions={paginationModel.filters.filterOptions}
                />
                <ExportAll 
                  paginationModel={paginationModel} 
                  columns={columns} 
                  // statusOptions={statusOptions} 
                />
              </Box>
              <Box>
                <TextField
                  value={quickFilterText}
                  onChange={(event) => setQuickFilterText(event.target.value)}
                  placeholder="Quick Search ..."
                  size="small"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: '0px',
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        height: '0.6rem',
                        // textTransform: 'uppercase',
                        fontSize: 'x-small'
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search sx={{fontSize:'x-small'}}/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <DataGrid
              rows={rowdata}
              columns={columns}
              // getRowId={(row) => row.id}
              //checkboxSelection={true}
              rowCount={allRowCount}  // Use the length of the filtered list
              pageSizeOptions={[5, 10, 20, 50, 100]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={handlePaginationChange}
              filterModel={filterModel}
              loading={loading}

              disableColumnMenu
              disableRowSelectionOnClick
              // disableColumnFilter
              // disableColumnSelector
              disableDensitySelector
              slots={{
                // toolbar: GridToolbar,
                noRowsOverlay: NoRowsLayout,
                loadingOverlay: Loading,
              }}
              // slotProps={{
              //   toolbar: {
              //     showQuickFilter: true,  
              //   },
              // }}
              // rowHeight={50}
              autoHeight
              getRowHeight={() => 'auto'}
              sx={{
                  '& .MuiDataGrid-cell': {
                  border: 1,
                  borderRight: 0,
                  borderTop: 0,
                  borderColor: 'lightgrey',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  borderTop: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  p:0,
                  height: 'fit-content',
                  minHeight:'0 !important',
                  height:'2rem'
                  },
                  '& .MuiDataGrid-columnHeader': {
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  '&:last-child': {
                      borderRight: 'none',
                  },
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#3e3e3e'
                  },
                  '& .MuiDataGrid-row': {
                  '&:last-child .MuiDataGrid-cell': {
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  },
                  '& .MuiDataGrid-cell:last-child': {
                      borderRight: 'none',
                  },
                  },
                  '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                  },
              }}
            />
          </Box>
        </Box>
      </Card>
      
      {/* ================== Add/Edit Customer Modal ================ */}
      <Modal
        open={openAddEditModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: (isMobile) ? '80%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            // border: '2px solid #ddd',
            boxShadow: 24,
            // p: 2,
          }}
          width={(isMobile) ? "95%" : "65%"}
        >
          <Card sx={{ minWidth: "100%" ,padding:'1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
              <Typography
                  gutterBottom
                  // variant='h5'
                  sx={{fontSize:'0.85rem',fontWeight:600}}
                  component='div'
                  // sx={{ padding: '20px' }}
              >
                {selectedCustomerDetail?'Edit Customer Detail':'Add New Customer'}
              </Typography>
              <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
              />
              <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleAddEditCustomerModal}>close</Button>
            </Stack>
          </Card>
          <Box sx={{}}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper elevation={3} style={{ padding: 16 }}>
                        <PersonalDetails 
                          personalDetails={selectedCustomerDetail}
                          onSubmitPersonal={handleAddEditCustomerModal} 
                          // userIdProp={id} personalDetails={personalDetails} onSubmitPersonal={handlePersonalSubmit} 
                        />
                    </Paper>
                </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  )
}