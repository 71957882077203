import {Autocomplete,Box,Button,CircularProgress, Divider, FormControl, FormControlLabel, Grid,Radio,RadioGroup,Select,TextField,Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller, FormProvider, useWatch, set } from 'react-hook-form';
import CoordinateDetails from './CoordinateDetails';
import { BookingDetailContext } from '../AddBooking';
import { StepperContext } from '../BookingForm';
import { CustomerService } from '../../Customer/Service';
import AddressDetails from './AddressDetails';
import { BookingService } from '../Service';

const CustomerAndLocation = () => {
  const {bookingData,setBookingData} = useContext(BookingDetailContext);
  const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset} = useContext(StepperContext);
  const FormMethod = useForm(
    { 
      mode: "onChange",
      defaultValues:{
        customer:bookingData?.customer||'',
        addressMode:bookingData?.addressMode||'select_address',
        selected_address:bookingData?.selected_address||null,
        address:bookingData?.address||'',
        apartmentRoadArea:bookingData?.apartmentRoadArea||'',
        houseFlatBlockNo:bookingData?.houseFlatBlockNo||'',
        landmark:bookingData?.landmark||'',
        city:bookingData?.city||'',
        latitude:bookingData?.latitude||'',
        longitude:bookingData?.longitude||'',
        pincode:bookingData?.pincode||'',
        state:bookingData?.state||'',
      }
    }
  );
  const {control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset} = FormMethod;


  const [isLoading,setIsLoading] = useState(false);
  const [customerList,setCustomerList] = useState([]);
  const [isLoadingAddressList,setIsLoadingAddressList] = useState(false);
  const [addressList,setAddressList] = useState([]);
  // fetch customer list
  const fetchCusomerlist = async()=>{
    setIsLoading(true)
    try {
      const customerListResponse = await CustomerService.unsortedCustomerList();
      // console.log("customer list Response:",customerListResponse);
      if(customerListResponse.success===1){
        setCustomerList(customerListResponse.data)
      }
    } catch (error) {
      
    }finally{
      setIsLoading(false);
    }
  }
  useEffect(()=>{  
    // console.log("customer list data useeffect is called");
    fetchCusomerlist();
  },[])

  // handle whenever addressMode is changed
  const addressMode = watch('addressMode');
  
  // if address is selected from the list then set the address details
  const selectedAddress = watch('selected_address');

  //if address mode changed reset the address related fields

  useEffect(()=>{
    if (addressMode!=='select_address'){
      setValue('selected_address',null,{shouldValidate:true});
    }else{
      if(selectedAddress){
        setValue('address',selectedAddress.address);
        setValue('houseFlatBlockNo',selectedAddress.houseFlatBlockNo);
        setValue('apartmentRoadArea',selectedAddress.apartmentRoadArea)
        setValue('city',selectedAddress.city);
        setValue('latitude',selectedAddress.latitude);
        setValue('longitude',selectedAddress.longitude);
        setValue('pincode',selectedAddress.pincode);
        setValue('state',selectedAddress.state);
        setValue('landmark',selectedAddress.landmark);
      }else{
        setValue('address', '');
        setValue('houseFlatBlockNo', '');
        setValue('apartmentRoadArea', '');
        setValue('city', '');
        setValue('latitude', '');
        setValue('longitude', '');
        setValue('pincode', '');
        setValue('state', '');
        setValue('landmark', '');
      }
    }
  },[addressMode])

   

   useEffect(()=>{
     if(selectedAddress){
      //  console.log("selected address:",selectedAddress);
       setValue('address',selectedAddress.address);
       setValue('houseFlatBlockNo',selectedAddress.houseFlatBlockNo);
       setValue('apartmentRoadArea',selectedAddress.apartmentRoadArea)
       setValue('city',selectedAddress.city);
       setValue('latitude',selectedAddress.latitude);
       setValue('longitude',selectedAddress.longitude);
       setValue('pincode',selectedAddress.pincode);
       setValue('state',selectedAddress.state);
       setValue('landmark',selectedAddress.landmark);
     }else{
      setValue('address', '');
      setValue('houseFlatBlockNo', '');
      setValue('apartmentRoadArea', '');
      setValue('city', '');
      setValue('latitude', '');
      setValue('longitude', '');
      setValue('pincode', '');
      setValue('state', '');
      setValue('landmark', '');
     }
 
   },[selectedAddress])

  useEffect(()=>{
    if (bookingData.customer && bookingData.address) {
      reset({
        customer:bookingData?.customer||'',
        addressMode:bookingData?.addressMode||'selecte_address',
        address:bookingData?.address||'',
        apartmentRoadArea:bookingData?.apartmentRoadArea||'',
        houseFlatBlockNo:bookingData?.houseFlatBlockNo||'',
        landmark:bookingData?.landmark||'',
        city:bookingData?.city||'',
        latitude:bookingData?.latitude||'',
        longitude:bookingData?.longitude||'',
        pincode:bookingData?.pincode||'',
        state:bookingData?.state||'',
        selected_address: bookingData?.addressMode==='select_address'?bookingData?.selected_address:null,
      })
      // console.log("booking data useeffect is called");
    }
  },[bookingData])
  

  
  // fetch adress list of selected customer
  const selectedCustomer = watch('customer');
  // const selectedCustomer = watch('customer');
  useEffect(()=>{
    if(selectedCustomer){
      // console.log("selected customer:",selectedCustomer);
      const fetchAddressList = async()=>{
        setIsLoadingAddressList(true)
        try {
          const addressListResponse = await BookingService.getAddressList(selectedCustomer.id);
          // console.log("address list Response:",addressListResponse);
          if(addressListResponse.success===1){
            setAddressList(addressListResponse.data)
          }
        } catch (error) {

        }finally{
          setIsLoadingAddressList(false);
        }
      }
      fetchAddressList();
    }
  },[selectedCustomer])

  /*..........related to customer list autocomplete................ */

  const getFullName = (profile) => {
    if (!profile) return '';
    return [profile.firstName, profile.middleName, profile.lastName]
      .filter(Boolean)
      .join(' ');
  };

  
  const completeStep1 = (data)=>{
    // console.log(" submitted customer and address:",data);
    setBookingData(pre=>{
      return {...pre,...data}
    })
    handleComplete();
    handleNext();
  }
  return (
    <FormProvider {...FormMethod}>
      <Box component={'form'} onSubmit={handleSubmit(completeStep1)} sx={{pb:'1%'}}>
        <Grid container>
          <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
            {/* first container....customer selection */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'600',fontSize:'0.85rem',fontFamily: 'Poppins'}}>Select Customer<span style={{color:'red'}}>*</span></Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={isLoading?'10':'12'}>
                        <Controller
                          name="customer"
                          control={control}
                          rules={{
                            required: 'Select customer',
                            validate: (value) => (value && Object.keys(value).length > 0) || 'Select customer'
                          }}
                          // defaultValue={null}  // Set a default value
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              options={customerList || []}  // Provide a fallback empty array
                              getOptionLabel={(option) => {
                                if (!option) return '';  // Handle null/undefined option
                                const profile = option.profile || {};  // Use empty object as fallback
                                const fullName = getFullName(profile);
                                return fullName ? `${fullName} (${option.mobileNumber || ''})` : '';
                              }}
                              value={value}
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  placeholder='Enter customer name or mobile Number'
                                  variant="outlined"
                                  error={!!error}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: '0.75rem',color:'#000' }, // Adjust size as needed
                                    placeholder:'Enter customer name or mobile Number'
                                  }}
                                  // helperText={error?.message}
                                />
                              )}
                              isOptionEqualToValue={(option, value) => {
                                if (!option || !value) return false;  // Handle null/undefined values
                                return option.id === value.id;
                              }}
                              noOptionsText="No customers found" // Custom text when no options match
                            />
                          )}
                        />
                      </Grid>
                      {isLoading && <Grid item xs={2}>
                        <CircularProgress/>
                      </Grid>}
                    </Grid>
                  
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{my:'2%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>

            {/* second container .... select address and enter location */}

            <Grid container>
              <Controller
                name='addressMode'
                control={control}
                // In the RadioGroup, modify the render method
                render={({field:{onChange, value}, fieldState:{error}})=>(
                  <FormControl fullWidth>
                    <RadioGroup
                      value={value} // Add this to ensure the default value is selected
                      onChange={(e) => {
                        onChange(e);
                      }}
                      aria-labelledby="address-selection-group"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <FormControlLabel 
                            value="select_address" 
                            disabled={!bookingData.customer && !watch('customer')}
                            control={<Radio />} 
                            label={<Typography sx={{fontWeight:600,fontSize:"0.85rem",fontFamily: 'Poppins'}}>Select from Saved Addresses<span style={{color:'red'}}>*</span></Typography>}
                            className="w-full"
                          />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                          <FormControlLabel 
                            value="manual_address" 
                            disabled={!bookingData.customer && !watch('customer')}
                            control={<Radio />} 
                            label={<Typography sx={{fontWeight:600,fontSize:"0.85rem",fontFamily: 'Poppins'}}>Enter New Address<span style={{color:'red'}}>*</span></Typography> }
                            className="w-full"
                            sx={{
                              fontWeight:600
                            }}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}              
              />
            </Grid>
            {/*  */}
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Controller
                  name="selected_address"
                  control={control}
                  rules={{
                    validate: (value) => {
                      const mode = addressMode;
                      if (mode === 'select_address' && !value) return 'Please select an address';
                      return true;
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    // use Autocomplete component for address
                    <Autocomplete
                      disabled={watch('addressMode') !== 'select_address' || (!bookingData.customer && !watch('customer'))}
                      autoComplete={false}
                      options={addressList || []}
                      getOptionLabel={(option) => {
                        if (!option) return '';
                        const address = option.address || '';
                        return address ? `${address}` : '';
                      }}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          placeholder='Select an address from the list'
                          variant="outlined"
                          error={!!error}
                          helperText={error?.message}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: '0.75rem',color:'#000' }, // Adjust size as needed
                            placeholder:'Select an address from the list'
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        return option.id === value.id;
                      }}
                      noOptionsText="No Address found"

                    />
                  )}
                />  
              </Grid>
              <Grid item xs={2}>{isLoadingAddressList && <CircularProgress/>}</Grid>
              <CoordinateDetails addressMode={addressMode} />
            </Grid>
            <AddressDetails addressMode={addressMode} />
          </Grid>
        </Grid>
        {/* =======Form Action Button Section ========*/}
        <Box sx={{display:'flex',justifyContent:'center',gap:2,mt:'.5%'}}>
            <Button disabled={activeStep === 0} onClick={handleBack} variant='contained' color='secondary'>Back</Button>
            <Button disabled={!isDirty} type='submit' variant='contained' color='primary'>Submit & Next</Button>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default CustomerAndLocation;
