import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {TextField,  Grid,  Box,  FormControl,  Select,  MenuItem,  Typography,  Card,  FormControlLabel,  Checkbox,  CardContent} from '@mui/material';
import statelist from '../../../utils/StateDistList.json'
export default function AddressDetails(props) {
  // const {addressMode} = props;
  const {control,watch,reset,setValue} = useFormContext();
  const addressMode = watch('addressMode');
  return (
    
    <Grid container sx={{p:'2% 5%'}}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Flat/House No<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='houseFlatBlockNo'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                    // disabled={disabled}
                    error={!!error}
                  />
                )}
              />  
            </Box> 
          </Grid>
          <Grid item xs={2}/>
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Apartment/ Road/ Area</Typography>
              <Controller
                control={control}
                name='apartmentRoadArea'
                rules={{
                  // required:'required',
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                  />
                )}
              />  
            </Box> 
          </Grid>

          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Landmark</Typography>
              <Controller
                control={control}
                name='landmark'
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                  />
                )}
              />  
            </Box> 
          </Grid>
          <Grid item xs={2}/>
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>State<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='state'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <FormControl fullWidth size='small'>
                    <Select
                      {...field}
                      value={field.value || ''}
                      disabled={addressMode=='select_address'}
                      sx={{
                        // minHeight: '0px',
                        // height: '1.6rem',
                        fontSize: '0.75rem',
                        '& .MuiSelect-select': {
                          // padding: '0 8px',
                          display: 'flex',
                          alignItems: 'center',
                        },
                      }}
                      error={!!error}
                    >
                      {statelist.map((listItem,index)=>(
                        <MenuItem sx={{fontSize:'0.75rem'}} key={index} value={listItem.state}>{listItem.state}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />  
            </Box> 
          </Grid>

          
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>City<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='city'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                    // disabled={disabled}
                    error={!!error}
                  />
                )}
              />  
            </Box> 
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Pincode<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='pincode'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    type='number'
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                    // disabled={disabled}
                    error={!!error}
                  />
                )}
              />  
            </Box> 
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Latitude<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='latitude'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    type='number'
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                    // disabled={disabled}
                    error={!!error}
                  />
                )}
              />  
            </Box> 
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography sx={{ fontSize:'0.85rem',fontWeight:600,fontFamily: 'Poppins' }}>Longitude<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name='longitude'
                rules={{
                  validate:(value)=>{
                    if (addressMode==='manual_address' && !value) return 'Empty Not allowed';
                    return true;
                  }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    inputProps={{
                      style:{
                        fontSize:'0.75rem'
                      }
                    }}
                    type='number'
                    disabled={addressMode=='select_address'}
                    // value={PersonalDetails[prefix]?.address}
                    fullWidth
                    size='small'
                    // disabled={disabled}
                    error={!!error}
                  />
                )}
              />  
            </Box> 
          </Grid>
        </Grid>
      </Grid>
    </Grid>
        
  );
}