import { Box, Button, Modal, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import {Controller, useForm} from 'react-hook-form'
import Swal from 'sweetalert2';
import { CustomerService } from '../Service';
import { Close } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const ChangeMobileNumber = ({currentMobileNumber,openModel,onCloseModel,verifyNewNumber}) => {
    const {control,handleSubmit,reset} =  useForm({
        mode:'onSubmit',
        defaultValues:{
            mobileNumber:''
        }
    });

    const mobileNumberSusmission = async (data) =>{
        // console.log ("New mobile Number :",data.mobileNumber);
        try {
            // Show loading state
            const loadingDialog = Swal.fire({
              title: 'Processing...',
              text: 'Please wait while Changing Mobile Number',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            // send otp to new mobile number
            const otpSendResponse = await CustomerService.sendOtptoVerifyNewNumber(
                {
                    currentMobileNumber:currentMobileNumber,
                    newMobileNumber:data.mobileNumber
                }
            );
            console.log("otp send response:", otpSendResponse);

            if (otpSendResponse.success == '1') {
                await Swal.close();
                reset();
                Swal.fire({
                    icon: 'success',
                    title: 'Successful!',
                    text: 'Otp Sent Successfully....Need to verify it' || otpSendResponse.message,
                    timer: 1500
                });
                verifyNewNumber(data.mobileNumber);
                return true;
            }else{
                await Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: otpSendResponse.message || 'Failed to send otp'
                });
                return false;
            }
      
          } catch (error) {
            // Always close the loading dialog in case of error
            await Swal.close();
            
            // console.error("Error While Changing Customer mobile number:", error);
            await Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'An error occurred while changing customer mobile number'
            });
            return false;
        }
    }
  return (
    <Modal
        open={openModel}
        // onClose={handleClose}
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                <Typography sx={{fontWeight:600,fontSize:'large'}}>Change Mobile Number</Typography>
                <Button size='small' variant='outlined' color='error' onClick={onCloseModel} startIcon={<Close/>} >close</Button>
            </Box>
            {/* <ChangeMobileNumber customerData = {selectedCustomerDetail} onCloseModel={handleModalClose}/> */}
            <Paper sx={{p:'5%',my:'2%'}}>
                <Box component={'form'} onSubmit={handleSubmit(mobileNumberSusmission)} sx={{display:'flex',justifyContent:'space-between'}}>
                    <Box sx={{display:'flex',gap:'3%',flex:1}}>
                        <Typography sx={{fontWeight:'600', texttTransform:'uppercase', display:'flex ',alignItems:'center'}}>Contact Number </Typography>
                        <Controller
                            name='mobileNumber'
                            control={control}
                            rules={{
                                required: 'Mobile number is required',
                                pattern: {
                                    value: /^[1-9][0-9]{9}$/,
                                    message: "Enter a valid 10-digit number not starting with 0"
                                },
                                validate: (value) => {
                                if (value && value.length !== 10) {
                                    return "Mobile number must be exactly 10 digits";
                                }
                                return true;
                                }
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    size='small'
                                    error={!!error}
                                    // helperText={error?.message}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                        field.onChange(value);
                                    }}
                                />
                            )}                       
                        />
                        
                    </Box>
                    <Button variant='contained' size='small' color='primary' type='submit'>Verify Number</Button>
                </Box>
            </Paper>
        </Box>
      </Modal>
    
  );
}

export default ChangeMobileNumber;
