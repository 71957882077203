import { Autocomplete, Box,Button,Checkbox,CircularProgress,FormControl,FormControlLabel,FormHelperText, Grid,Radio,RadioGroup,TextField,Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form'
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BookingDetailContext } from '../AddBooking';
import { StepperContext } from '../BookingForm';
import { BookingService } from '../Service';
import Swal from 'sweetalert2';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ScheduleAndAddress = () => {
  const {bookingData,setBookingData} = useContext(BookingDetailContext);
  const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset} = useContext(StepperContext);
  const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
    mode: "onChange",
    defaultValues:{
      services:bookingData?.services || [],
      timeSlot:bookingData?.timeSlot || '',
      serviceDate:bookingData?.serviceDate || ''
    }
  });

  const [loadingTimeSlotList,setLoadingTimeSlotList] = useState(false);
  const [timeSlotList,setTimeSlotList] = useState([]);

  const services = watch('services');
  const selectedDate= watch('serviceDate');

  // Add this new useEffect to sync form with bookingData
  useEffect(() => {
    // Reset form values when bookingData changes
    reset({
      services: bookingData?.services || [],
      timeSlot: bookingData?.timeSlot || '',
      serviceDate: bookingData?.serviceDate || ''
    });
  }, [bookingData, reset]);

  useEffect(()=>{
    // setValue('timeSlot','');
    // setTimeSlotList([])
    // console.log("serviceDate:",selectedDate);
    // console.log("date from bookingData:",bookingData?.serviceDate);
    let totalServiceTime = 0;
    if (services.length>0) {
      // setSelectedServices(services.map(service => ({ ...service, quantity: 1 })));
      services.map((service)=>totalServiceTime=totalServiceTime+service.serviceTime);
    }else{
      setValue('serviceDate','')
      setValue('timeSlot','')
    }
    if (selectedDate && services.length>0) {
      // console.log("selected Date:",selectedDate)
        if(!bookingData.timeSlot || bookingData.serviceDate!==selectedDate){
          setValue('timeSlot','')
        }else{
          setValue('timeSlot',bookingData.timeSlot)
        }
        const fetchTimeSlots= async()=>{
          setLoadingTimeSlotList(true);
          const data={    
            customerId: bookingData?.customer?.id,
            providerId: bookingData?.serviceExpert,
            serviceTimeTotal: totalServiceTime,
            timeSlotDate: selectedDate
          }
          // console.log("required data for getting time slot:",data);
          try {
            const timeSlotResponse = await BookingService.timeSlots(data);
            // console.log("Time slot Response:",timeSlotResponse);
            if(timeSlotResponse.success=="1"){
              if (timeSlotResponse?.data.length>0) {
                setTimeSlotList(timeSlotResponse.data);
              } else {
                Swal.fire('Time Slot for selected Date not available','','error');
                setValue('serviceDate','')
              }
              
            }else{
              Swal.fire(timeSlotResponse.message,'','error');
            }
          } catch (error) {

          }finally{
            setLoadingTimeSlotList(false);
          }
        }
        fetchTimeSlots();
    }
  },[selectedDate,services])

  

  const completeStep3=(data)=>{
    // console.log(" submitted Schedule and Address:",data);
    setBookingData(pre=>{
      return {...pre,...data}
    })
    handleComplete();
    handleNext();
  }
  return (
    <Box component={'form'} onSubmit={handleSubmit(completeStep3)} sx={{pb:'1%'}}>
      <Grid container spacing={2} >
        <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
        <Grid container spacing={2} >
            {/* ............left section................... */}
            <Grid item xs={12} lg={5}>
              <Grid container>
                <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'600',fontSize:'0.85rem',fontFamily: 'Poppins'}}>Select Date<span style={{color:'red'}}>*</span></Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} xs={12}>
                      <Controller
                        name="serviceDate"
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (!value) return "This field is required";
                            const date = dayjs(value);
                            const today = dayjs().startOf('day');
                            return date.isValid() && (date.isSame(today, 'day') || date.isAfter(today)) || "Booking Date must be today or in the future";
                          }
                        }}
                        render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              {...restField}
                              sx={{
                                '& . iconButton': {
                                  padding: 0.5,
                                  fontSize: '1rem'
                                }
                              }}
                              // disabled={!watch('services').length>0 || !watch('customer')}
                              value={value ? dayjs(value) : null}
                              onChange={(newValue) => {
                                // Store the date in YYYY-MM-DD format for database compatibility
                                onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                              }}
                              format="DD-MM-YYYY" // Display format for user
                              minDate={dayjs()}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  fullWidth: true,
                                  required: true,
                                  size: 'small',
                                  inputProps: {
                                    style: { fontSize: '.75rem' }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ............central gap................... */}
            <Grid item xs={'none'} lg={2}>
              
            </Grid>
            {/* ............right section................... */}
            <Grid item xs={12} lg={5}>
              <Grid container>
                <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                        <Grid item lg={7} xs={12}>
                            <Typography sx={{fontWeight:'600',fontSize:'0.85rem',fontFamily: 'Poppins'}}>Select Time Slot<span style={{color:'red'}}>*</span></Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={loadingTimeSlotList?'10':'12'} xs={12}>
                        
                        <Controller
                          name="timeSlot"
                          control={control}
                          rules={{
                            required: 'Please select a time slot',
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl error={!!error} fullWidth>
                              <Autocomplete
                                id="time-slot-autocomplete"
                                options={timeSlotList}
                                // sx={{
                                //   display: services.length > 0 ? 'block' : 'none',
                                // }}
                                disabled={!watch('serviceDate') || !bookingData.customer}
                                getOptionLabel={(option) => {
                                  // Handle both string (from field value) and object (from options) cases
                                  // const slot = typeof option === 'string' ? JSON.parse(option) : option;
                                  return `${dayjs(option.startTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(option.endTime, 'HH:mm:ss').format('h:mm A')}`;
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  // Handle both string and object cases for comparison
                                  const valueObj = typeof value === 'string' ? JSON.parse(value) : value;
                                  return option.startTime === valueObj.startTime && option.endTime === valueObj.endTime;
                                }}
                                value={field.value ? (typeof field.value === 'string' ? JSON.parse(field.value) : field.value) : null}
                                onChange={(_, newValue) => {
                                  field.onChange(newValue ? JSON.stringify(newValue) : null);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { fontSize: '0.75rem',color:'#000' },
                                    }}
                                    placeholder="Select Time Slot"
                                    variant="outlined"
                                    error={!!error}
                                    size="small"
                                  />
                                )}
                                ListboxProps={{
                                  sx: {
                                    '& .MuiAutocomplete-option': {
                                      padding: '8px 16px',
                                      fontSize: '0.75rem',
                                      '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                      },
                                    },
                                  },
                                }}
                              />
                              {error && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                          )}
                        />
                        {/* <Controller
                            name="timeSlot"
                            control={control}
                            rules={{
                                required: 'Please select a time slot',
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl component="fieldset" error={!!error} fullWidth>
                                    <RadioGroup 
                                      {...field}
                                      value={field.value ? JSON.stringify(field.value) : ''}
                                      onChange={(event) => field.onChange(JSON.parse(event.target.value))}
                                      sx={{
                                          display: services.length>0?'flex':'none',
                                          flexDirection: 'row',
                                          flexWrap: 'wrap',
                                      }}
                                    >
                                        {timeSlotList.map((slot, index) => (
                                          <Box key={index} sx={{ margin: '4px',width:'fit-content' }}>
                                            <FormControlLabel
                                                value={JSON.stringify(slot)}
                                                control={<Radio size="small" />}
                                                label={
                                                    <Typography variant="body2">
                                                        {slot.startTime.slice(0, -3)} - {slot.endTime.slice(0, -3)}
                                                    </Typography>
                                                }
                                                disabled={!watch('serviceDate') || !watch('customer')}
                                                sx={{ 
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '4px',
                                                    padding: '4px 8px',
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            />
                                          </Box>
                                        ))}
                                    </RadioGroup>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                </FormControl>
                            )}
                        /> */}
                        {/* <Controller
                          name='timeSlot'
                          control={control}
                          rules={{ required: 'Select timeSlot' }}
                          render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              // disabled={!customer || !serviceExpert}
                              disableCloseOnSelect
                              options={timeSlotList}
                              getOptionLabel={(option) => option.title}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  error={!!error}
                                  variant="outlined"
                                  placeholder="Select services"
                                />
                              )}
                              onChange={(_, newValue) => {
                                const servicesWithQuantity = newValue.map(service => ({
                                  ...service,
                                  quantity: service.quantity || 1
                                }));
                                field.onChange(servicesWithQuantity);
                              }}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderTags={() => null}
                            />
                          )}
                        /> */}
                      </Grid>
                      
                      {loadingTimeSlotList && <Grid item xs={2}><CircularProgress/></Grid>}
                    </Grid>
                </Grid>
              </Grid>
          </Grid>
            {/* ............right section ends................... */}
          </Grid>
        </Grid>
      </Grid>
      {/* =======Form Action Button Section ========*/}
      <Box sx={{display:'flex',justifyContent:'center',gap:2,mt:'.5%'}}>
          <Button disabled={activeStep === 0} onClick={handleBack} variant='contained' color='secondary'>Back</Button>
          <Button disabled={!isDirty} 
            type='submit' 
            // onClick={handleNext}
            variant='contained' color='primary'>Submit & Next</Button>
      </Box>
    </Box>
  );
}

export default ScheduleAndAddress;
