import React, { useState,useEffect } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card, Chip, Modal, Stack } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ArrowBack, Close } from '@mui/icons-material';
import { CustomerService } from '../Service';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';
import AccountStatusIndicator from './AccountStatusIndicator';
import VerifyOtp from './VerifyOtp';
import { isMobile } from 'react-device-detect';
import Loading from '../../../components/Loader/Loading';
import BookingHistory from './BookingHistory';
import { PermissionGuard } from '../../../components/PermissionGuard';
import BasicDetails from './PersonalDetail';
import PersonalDetails from '../PersonalDetail';
import ChangeMobileNumber from './ChangeMobileNumber';


const CustomerProfile = () => {
  const {id} = useParams();
  const navigate =  useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const [bookingList, setBookingList] = useState([]);

 /************ customer mobile/otp verification related ***********/
  const [customerVerificationModal,setCustomerVerificationModal] = useState(null);
  const [selectedCustomerDetail,setSelectedCustomerDetail] = useState(null);
  const sendOtp = async (customerData) => {
  //  setAnchorEl(null);
    // console.log("selected customer :",customerData)
    const result = await Swal.fire({
      text: "Do you want to Verify Customer now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Verify now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
  
    if (result.isConfirmed) {
    //  setSelectedCustomerDetail(customerData);
      try {
        // Show loading state
        const loadingDialog = Swal.fire({
          title: 'Processing...',
          text: 'Please wait while sending the otp',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const response = await CustomerService.verifyCustomerByMobileNo({ mobileNumber: customerData.mobileNumber });
        // console.log("mobile number submission response:", response);
  
        // Always close the loading dialog
        await Swal.close();
  
        if (response.success == '1') {
          setCustomerVerificationModal('verify Otp');
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.message || 'Failed to send otp'
          });
          return false;
        }
  
      } catch (error) {
        // Always close the loading dialog in case of error
        await Swal.close();
        
        // console.error("Error Verifying Customer mobile number:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while Verifying customer'
        });
        return false;
      }
    } else {
      return false;
    }
  }
  const handleModalClose = ()=>{
    fetchData();
    setNumberToUpdate(null);
    setCustomerVerificationModal(null);
  }
  

  const fetchData = async () => {
    setIsLoading(true);
    const response = await CustomerService.fetchCustomerDetailById(id);
    console.log('response:',response)
    const {bookingList,...restAll} =response.data
    setPersonalDetails(restAll);
    setBookingList(bookingList);
    setIsLoading(false);
  }

  useEffect(() => {
    
    if (id && id>0) {
      fetchData();
    }
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={0}>
            <BasicDetails personalDetails={personalDetails} fetchData = {fetchData} customerVerificationModal={customerVerificationModal} setCustomerVerificationModal={setCustomerVerificationModal}/>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={0}>
            <BookingHistory bookingList={bookingList}/>
          </Grid>
        );
      default:
        return null;
    }
  };

  /************* Edit cuistomer Related *************/
  const [openAddEditModal,setOpenAddEditModal] =  useState(false);
  // const customerDataForEdit = {
  //   id: personalDetails.id,
  //   firstName: personalDetails?.profile?.firstName,
  //   middleName: personalDetails?.profile?.middleName,
  //   lastName: personalDetails?.profile?.lastName,
  //   mobileNumber: personalDetails?.mobileNumber,
  //   gender: personalDetails?.profile?.gender,
  //   email: personalDetails?.profile?.email,
  //   dob: personalDetails?.profile?.dob
  // }
  const handleEdit = async()=>{
    const result = await Swal.fire({
      text: "Do you want to Edit Customer Detail now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Edit now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
    if (result.isConfirmed) {
      setSelectedCustomerDetail({
        id: personalDetails.id,
        firstName: personalDetails?.profile?.firstName,
        middleName: personalDetails?.profile?.middleName,
        lastName: personalDetails?.profile?.lastName,
        mobileNumber: personalDetails?.mobileNumber,
        gender: personalDetails?.profile?.gender,
        email: personalDetails?.profile?.email,
        dob: personalDetails?.profile?.dob
      });
      setOpenAddEditModal(true)
    }else{
      return false
    }
  }
  const handleAddEditCustomerModal = async ()=>{
    fetchData();
    setSelectedCustomerDetail(null);
    setOpenAddEditModal(false);
  }

  const [numberToUpdate,setNumberToUpdate] = useState(null);
  return (
    <>
      {/* <Card sx */}
      {isLoading && <Loading/>}
      <PageHeaderLayout headerTitle={'Customer Profile'}>
        <PermissionGuard feature='customer' permission='canEdit'>
          {/* <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={handleEdit}>
            Edit
          </Button> */}
        </PermissionGuard>
      </PageHeaderLayout>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} lg={3}>
          <Paper>
            <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={''}
                  // alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              <Typography variant="body1" sx={{fontSize:'0.85rem'}}>{`${personalDetails?.profile?.firstName || ''} ${personalDetails?.profile?.middleName || ''} ${personalDetails?.profile?.lastName || ''}`}</Typography>
              {!isLoading &&
                <AccountStatusIndicator customerData={personalDetails} fetchUserDetails={fetchData} setOpenOtpModal={setCustomerVerificationModal} />
              }
              </Box>
            <Tabs
              
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
              >
              <Tab label="Personal Details" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
              <Tab label="Booking History" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Box sx={{ flexGrow: 1, }}>
            <Box elevation={3} >
              {renderForm()}
            </Box>
            {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <PermissionGuard feature='service-expert' permission='canEdit'>
                <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}`)}>
                  Edit
                </Button>
              </PermissionGuard>
            </Box> */}
          </Box>
        </Grid>
      </Grid>

      {/* ================ Verify Customer Modal=============== */}
      <Modal
        open={customerVerificationModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
          <Box sx={{textAlign:'end'}}>
            <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleModalClose}>close</Button>
          </Box>
          {customerVerificationModal == 'verify otp' && <VerifyOtp customerMobileNumber={personalDetails?.mobileNumber}
            mobileNumber={numberToUpdate? numberToUpdate: personalDetails.mobileNumber}
            sendOtp = {sendOtp}
            setOpenOtpModal={setCustomerVerificationModal}
            onClose={handleModalClose}
          />}
          {customerVerificationModal == 'change mobile number' &&
            <ChangeMobileNumber
              openModel={customerVerificationModal == 'change mobile number' ? true :false} 
              currentMobileNumber = {personalDetails?.mobileNumber} 
              onCloseModel={()=>{
                setCustomerVerificationModal(null);
              }}
              verifyNewNumber={(newNumber)=>{
                // fetchData();
                console.log('new number:',newNumber)
                setNumberToUpdate(newNumber);
                setCustomerVerificationModal('verify otp and update number');
              }}
            />
          }
          {customerVerificationModal == 'verify otp and update number' && 
            <VerifyOtp 
              mobileNumber={personalDetails?.mobileNumber}
              newMobileNumber={numberToUpdate}
              sendOtp = {sendOtp}
              setOpenOtpModal={setCustomerVerificationModal}
              onClose={handleModalClose}
            />
          }
        </Box>
      </Modal>

      {/* ================== Add/Edit Customer Modal ================ */}
      <Modal
        open={openAddEditModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: (isMobile) ? '80%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            // border: '2px solid #ddd',
            boxShadow: 24,
            // p: 2,
          }}
          width={(isMobile) ? "95%" : "65%"}
        >
          <Card sx={{ minWidth: "100%" ,padding:'1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
              <Typography
                  gutterBottom
                  sx={{
                    fontSize:'0.85rem',
                    fontWeight:600
                  }}
                  // sx={{ padding: '20px' }}
              >
                {'Edit Customer Detail'}
              </Typography>
              <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
              />
              <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleAddEditCustomerModal}>close</Button>
            </Stack>
          </Card>
          <Box sx={{}}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper elevation={3} style={{ padding: 16 }}>
                        <PersonalDetails
                          personalDetails={selectedCustomerDetail}
                          onSubmitPersonal={handleAddEditCustomerModal}
                        />
                    </Paper>
                </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerProfile;